import {Component, Input, OnInit, ElementRef, Renderer2} from '@angular/core';
import {BentoAlertItemOptions, BentoSideNavItem} from '@bento/bento-ng';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
@Component({
  selector: 'app-firm-details',
  templateUrl: './firm-details.component.html',
})
export class FirmDetailsComponent implements OnInit {
  isDisabled: any;
  constructor(private service: BaseService,
     private localStorageService: LocalStorageService,
     private el: ElementRef, private renderer: Renderer2 ) {}
  @Input() isNewFirm;
  @Input() firmId;
  @Input() isFAdmin;
  @Input() isTAdmin;
  fmsType;
  alerts: BentoAlertItemOptions[] = [];
  isStaffingratioEnable = false;
  is3eCloudEnable = false;
  details = true;
  detailsReadonly = false;
  isContentDisplay = false;
  permissionNameLbl: string;
  isItemsReady = false;

  items: BentoSideNavItem[] = [
    {
      label: 'General',
      selected: true,
      action: () => {
        //this.detailsDiv();
        this.showHideUserTabSideNav('General');
      },
      data: {isVisible: true},
    },
    {
      label: 'Parameters',
      action: () => {
        this.showHideUserTabSideNav('Parameters');
      },
      data: {isVisible: false},
      collapsed: true,
      items: [
        {
          label: 'FMS',
          action: () => {
            this.showHideUserTabSideNav('FMS');
          },
          data: {isVisible: false},
        },
        {
          label: 'General',
          action: () => {
            this.showHideUserTabSideNav('General child');
          },
          data: {isVisible: false},
        },
        {
          label: 'Timekeeper',
          action: () => {
            this.showHideUserTabSideNav('Timekeeper');
          },
          data: {isVisible: false},
        },
        {
          label: 'Practice',
          action: () => {
            this.showHideUserTabSideNav('Practice');
          },
          data: {isVisible: false},
        },
        {
          label: 'General ledger',
          action: () => {
            this.showHideUserTabSideNav('General ledger');
          },
          data: {isVisible: false},
        },
      ],
    },
    {
      label: 'Permissions',
      action: () => {
        this.permissionNameLbl = 'metrics';
        this.showHideUserTabSideNav('Permissions');
      },
      data: {isVisible: false},
      collapsed: true,
      items: [
        {
          label: 'Metrics',
          action: () => {
            this.permissionNameLbl = 'metrics';
            this.showHideUserTabSideNav('Metrics');
          },
          data: {isVisible: false},
        },
        {
          label: 'Practice groups',
          action: () => {
            this.permissionNameLbl = 'practice groups';
            this.showHideUserTabSideNav('Practice groups');
          },
          data: {isVisible: false},
        },
        {
          label: 'Offices',
          action: () => {
            this.permissionNameLbl = 'offices';
            this.showHideUserTabSideNav('Offices');
          },
          data: {isVisible: false},
        },
        {
          label: 'Titles',
          action: () => {
            this.permissionNameLbl = 'titles';
            this.showHideUserTabSideNav('Titles');
          },
          data: {isVisible: false},
        },
      ],
    },
    {
      label: 'Staffing ratio subscription',
      selected: true,
      action: () => {
        this.showHideUserTabSideNav('Staffing ratio subscription');
      },
      data: {isVisible: false},
    },
  ];
  selectedLabel: string = this.items[0].label;
  cloud3eObj = {
    label: '3E cloud',
    selected: true,
    collapsed: true,
    action: () => {
      this.showHideUserTabSideNav('3E cloud');
    },
    items: [
      {
        label: 'Client credentials',
        action: () => {
          this.showHideUserTabSideNav('Client credentials');
        },
        data: {isVisible: false},
      },
      {
        label: 'Create extract',
        selected: true,
        action: () => {
          this.showHideUserTabSideNav('Create extract');
        },
        data: {isVisible: false},
      },
    ],
    data: {isVisible: false},
  };
  async ngOnInit(): Promise<void> {
    if (this.isNewFirm)
      this.items.forEach((item) => {
        if (item.label == 'Permissions' || item.label == 'Parameters') {
          item.disabled = this.isNewFirm;
        }
      });

    if (this.isFAdmin) {
      //this.detailsReadonly = true;
      // this.items.forEach((item) => {
      this.items = this.items.filter((item) => item.label == 'General');
      // });
      this.items[0].data.isVisible = true;
    }
    //Enable/Disable staffing ration based on feature toggl
    this.isStaffingratioEnable = await this.getFeatureFlag('STAFFING-RATIO');
    await this.getFmsType();
    this.is3eCloudEnable = await this.getFeatureFlag('3E-CLOUD');
    if (this.fmsType == '5' && this.is3eCloudEnable) {
      this.items.push(this.cloud3eObj);
      this.showHideUserTabSideNav('General');
    }
    if (!this.isFAdmin) this.removeItemBasedOnFeatureFlag(this.isStaffingratioEnable, 3);

    if (!this.isTAdmin) {
      this.isStaffingratioEnable = false;
    }
    this.isItemsReady = true;
  }
  ngAfterViewChecked(): void { 
    this.removeAriaExpandForAccessibility();
  }
  removeItemBasedOnFeatureFlag(flag: boolean, index) {
    if (!flag) {
      this.items.splice(index, 1);
    }
  }
  onSelect(item: BentoSideNavItem) {
    this.selectedLabel = item.label;
  }

  showHideUserTabSideNav(label: string) {
    this.items.forEach((element: BentoSideNavItem, index) => {
      let indexCount;
      if (element.items && element.items.length > 0) {
        element.items.forEach((elementChild, childIndex) => {
          if (
            ('General child' === label && elementChild.label == 'General') ||
            (elementChild.label === label && 'General' != label)
          ) {
            elementChild.data.isVisible = true;
            indexCount = index;
          } else {
            element.data.isVisible = false;
            element.items[childIndex].data.isVisible = false;
          }
        });
        //for main parent only ex. parameter ,permission, general
        if (element.label === label) {
          element.data.isVisible = true;
          element.items[0].data.isVisible = true;
          this.items[index].items[0].selected = true;
        }
      } else {
        if (element.label === label) {
          element.data.isVisible = true;
        } else {
          element.data.isVisible = false;
        }
      }
    });
  }
  getFeatureFlag(flagName: string): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/' + flagName, '')
      .toPromise()
      .then((result) => {
        this.isContentDisplay = true;
        return result[flagName];
      });
  }

  getFmsType() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/' + this.firmId, '')
      .toPromise()
      .then((firmparametersResult) => {
        this.fmsType = firmparametersResult.financialManagementSystem.financialManagementSystem;
      });
  }

  refreshSideNavigation(addTab: boolean) {
    let cloud3EObj = this.items.filter((item) => item.label == '3E cloud');
    if (addTab) {
      if (!cloud3EObj) this.items.push(this.cloud3eObj);
    } else {
      if (this.items.length > 4 && cloud3EObj) this.items.splice(4, 1);
    }
    this.showHideUserTabSideNav('FMS');
  }


  removeAriaExpandForAccessibility(){
    const liElements = this.el.nativeElement.querySelectorAll('li.bento-side-nav-branch.ng-star-inserted');
    liElements.forEach(li => {
      this.renderer.removeAttribute(li, 'aria-expanded'); 
    });
  }

}
