import {Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {BentoComboboxOptions} from '@bento/bento-ng';
import {AxeToolCommonMethodsService} from 'src/app/core/services/axe-tool-common-methods/axe-tool-common-methods.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {_fixedSizeVirtualScrollStrategyFactory} from '@angular/cdk/scrolling';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {Point} from '@grapecity/wijmo';
import {WjFlexChart} from '@grapecity/wijmo.angular2.chart';
import {WjTransposedGrid} from '@grapecity/wijmo.angular2.grid.transposed';
import {NgForm} from '@angular/forms';
import {PivotRequestBody} from 'src/app/core/models/pivotRequestBody.model';
import {SeriesVisibility} from '@grapecity/wijmo.chart';
import CreateViewModel, {
  CardTitles,
  DescriptionSummary,
  FixedTimeRange,
  Metric,
  PeerGroupDetails,
  PeerGroupInfo,
  ProcessedBaseTableData,
  ProcessedMainData,
  ViewDetails,
  ViewsSettings,
} from 'src/app/core/models/create-view.model';
import FirmBasicsModel from 'src/app/core/models/firm-basics.model';
import PeerCheckViewColumns from 'src/app/core/models/peerCheckViewColumns.model';
import {PeerCheckServiceService} from '../../peer-check/peer-check-service.service';
import {ViewsSettingsComponent} from '../../create-view/views-settings/views-settings.component';
import {v4 as uuidv4} from 'uuid';
import {colorPalette} from 'src/app/shared/constants';
import {ExportModalComponent} from '../../export-modal/export-modal.component';
import {GlobalService} from 'src/app/core/services/global/global.service';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import * as wjInput from '@grapecity/wijmo.input';
import $ from 'jquery';

@Component({
  selector: 'app-view-data-grid',
  templateUrl: './view-data-grid.component.html',
})
export class ViewDataGridComponent implements OnInit, OnDestroy {
  alerts: BentoAlertItemOptions[] = [];
  isCollapsed = false;
  validatePattern = '^(0[1-9]|10|11|12)/([0-9]{4})$';
  validations: any;
  dragIndex: number;
  @Input() userSettingsModel: CreateViewModel = new CreateViewModel();
  @Input() firmBasicData: FirmBasicsModel;
  @Input() pcViewCols: PeerCheckViewColumns;
  @Input() disableExportButton: boolean;
  @Input() viewDetails: ViewDetails;
  @Input() shouldCardDisplay = false;
  @Input() shouldDisplayPivot = false;
  @Input() shouldCheckMetricGroup = false;
  @Input() allowViewSettings = false;
  @Input() showDescription = false;
  @Input() showCustomDescription = false;
  @Input() expandDataRaw: any;
  @Input() fromCreateView = false;
  @Input() metricItemsStream: any;
  @Input() isTAdmin: boolean;
  @Input() callerComponent: string;
  @Output() dataLoadingStateChange = new EventEmitter<boolean>();
  @Output() dragDone = new EventEmitter();

  defaultGriddata: any;
  gridHeaders = [];
  openPPG = false;
  pivotRequestBody = {};
  selectedRowElement: HTMLElement;
  pivotIndex: string;
  columns: {header: string; binding: string; visible: boolean; format: string}[];
  columnCount: number;
  showDetailsTable = false;
  showBasicTable = false;
  isDataTableOrGraph = 'Grid';
  selectedMetric: Metric = null;
  mainGridBaseFlexGrid: WjTransposedGrid | wjGrid.FlexGrid;
  mainGridFlexChart: WjFlexChart;
  mainGridBaseTabledata: any;
  mainGridBaseTableColumn: any;
  mainGridChartSeries: any;
  editReorderFlag = false;
  groupBy: any;
  metricSelectionResponse: any;
  modalTitle: String = '';
  disableDropdowns = true;
  isDataLoading = true;
  errorMessage = '';
  allowedPartnerYearTitles = [-10, 1, 2];
  allowedAssociateYearTitles = [4];
  allowedSrAssociateYearTitles = [3];
  isPartnerYear = false;
  isAssociateYear = false;
  isSrAssociateYear = false;
  firmRules = [];
  metricData: any;
  isDetails = '';
  has_levels: boolean;
  report_type = '';
  can_pivot: boolean;
  metricBasicData = {};
  reorder = false;
  selectedOption: any;
  peerGroupDetail: PeerGroupInfo;
  requestBodyForPivot = new PivotRequestBody();
  metricIdArr = [];
  gridsSelectedRow = 0;
  gridsSelectedColHeader = true;
  gridsSelectedCol = 0;
  cardGridsSelectedRow = 0;
  cardGridsSelectedCol = 0;
  isFocusedForFirstTime = true;
  lastPressedKey: any;
  @ViewChild('listOfFirmsModal') listOfFirmsModalContent: any;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjGrid.FlexGrid;
  @ViewChild('transposedFlex', {static: true}) transposedGrid: wjGrid.FlexGrid;
  customOverlay: boolean;

  @ViewChild('createView') createViewForm: NgForm;
  clearStatus: boolean;
  selectedTimeRange: string;
  isLineChartAvailable: any;
  ppgText: string[];
  isCopyEditRequest: boolean;
  isCustomRequest: boolean;
  showExportButton = false;
  isInputPanelDataLoading: boolean;
  isSharedView: boolean;
  subscription: Subscription[] = [];
  summary: DescriptionSummary;
  availableInputValues: any;
  yoyHeader: string;

  //---------
  practiceData = [];
  rollingTimeRangeItemsStream: BehaviorSubject<any>;

  peerGroupItemsStream: BehaviorSubject<any>;
  practiceGroupItemsStream: BehaviorSubject<any>;
  officeItemsStream: BehaviorSubject<any>;
  titleItemsStream: BehaviorSubject<any>;
  associateYearItemsStream: BehaviorSubject<any>;
  srAssociateYearItemsStream: BehaviorSubject<any>;
  partnerYearItemsStream: BehaviorSubject<any>;
  cutomOverlayStream: BehaviorSubject<any>;
  customOverlayModel: any;
  currentSelectedDetails: any;
  showReorderCheckbox: boolean;
  allowCopyPaste: boolean;

  //time range
  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    autoSelect: false,
    labelFormatter: (row) => `${row.name}`,
  };

  processedMainGridData: ProcessedMainData;
  processedBaseTableData: ProcessedBaseTableData;

  offGroups = [];
  expandData: any;

  emptyGridObj = [
    {
      values: [
        {
          metrics: [
            {
              metricName: null,
              firmValue: null,
              firmValueFormat: null,
              firmDelta: null,
              firmDeltaFormat: null,
            },
          ],
        },
      ],
    },
  ];
  selectedFirmId: any;
  isColumnFreezed: boolean;
  frozenColumnCount: number;
  colorPalette: string[];
  gridClickFlag = false;
  transposeGridClickFlag = false;

  /********Metric Hyperlink cell template  */
  activePopup = null;
  templateMetricNameLink = (ctx, cell) => {
    let text = ctx.item.metricName ? '${item.metricName}' : '';
    if (!text.length) {
      return '--';
    }

    return CellMaker.makeLink({
      text: text,
      label: 'Link: ${item.metricName }',
      click: (e, ctx) => {
        this.handleMetricSelection(ctx.item);
      },
      attributes: {
        tabIndex: -1,
      },
    }).call(this, ctx, cell);
  };

  popup: wjInput.Popup;
  _lastFocusCell;
  isMainGridKeyDownCalled = 0;
  constructor(
    public modalService: NgbModal,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    public router: Router,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    public _service: PeerCheckServiceService,
    private globalservice: GlobalService,
    private axeToolService: AxeToolCommonMethodsService
  ) {
    this.isInputPanelDataLoading = true;
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Enter valid date format',
      },
    };
    // Old color palette for Views
    // [
    //   'rgba(50,77,170,1)',
    //   'rgba(53,130,118,1)',
    //   'rgba(107,81,163,1)',
    //   'rgba(218,117,69,1)',
    //   'rgba(204,0,0,1)',
    //   'rgba(0,204,163,1)',
    //   'rgba(61,109,204,1)',
    //   'rgba(86,86,86,1)',
    //   'rgba(0,0,0,1)'
    // ]
    this.colorPalette = colorPalette;

    if (!this.userSettingsModel) {
      this.userSettingsModel.cardTitles = [];
      this.userSettingsModel.isRollingTimeRange = true;
      this.userSettingsModel.timeRangeSelected = 'Rolling';
      this.userSettingsModel.metricSelectedItems = [];
      this.userSettingsModel.peerGroupSelectedItems = [];
      this.userSettingsModel.officeSelectedItems = [];
      this.userSettingsModel.practiceGroupSelectedItems = [];
      this.userSettingsModel.titleSelectedItems = [];
      this.userSettingsModel.associateYearSelectedItems = [];
      this.userSettingsModel.srAssociateYearSelectedItems = [];
      this.userSettingsModel.partnerYearSelectedItems = [];
      this.userSettingsModel.descriptionList = [];
      this.userSettingsModel.fixedTimeRange = new FixedTimeRange();
      this.userSettingsModel.rollingTimeRangeSelectedItems = null;
      this.userSettingsModel.viewsSettings = new ViewsSettings();
    }
    this.yoyHeader = 'YOY growth';
    this.isSharedView = this.peercheckMessageHandlerService.getrequestFromSharedViews;

    if (this.peercheckMessageHandlerService) {
      this.processedMainGridData = this.peercheckMessageHandlerService.processDataForMainGrid(
        this.emptyGridObj,
        this.userSettingsModel
      );
    }
    // let dataLoader = this._service.isDataLoadingMessage.subscribe((isDataProcessing: boolean) => {
    //   this.isDataLoading = isDataProcessing;
    //   // this.updateDataLoadingState(this.isDataLoading);
    // });
    // this.subscription.push(dataLoader);
  }
  ngOnDestroy(): void {
    if (!this.router.url.includes('create-view')) {
      eventDispatcher.next({type: ActionTypes.SetProcessedData, payload: null});
    }
    if (this.subscription && this.subscription.length) {
      this.subscription.forEach((subsc) => {
        subsc.unsubscribe();
      });
    }
  }

  onReorderChange(flex: any) {
    if (this.reorder) {
      if (this.flexGrid && this.flexGrid.rowHeaders && this.flexGrid.rowHeaders.columns[0]) {
        this.flexGrid.rowHeaders.columns[0].width = 60;
        this.flexGrid.rowHeaders.columns[0].cssClass = 'cell-border bento-icon-drag-drop';
        this.flexGrid.headersVisibility = wjGrid.HeadersVisibility.All;
        this.flexGrid.headersFocusability = wjGrid.HeadersFocusability.All;
        this.flexGrid.select(new wjGrid.CellRange(-0, 1), true, this.flexGrid.columnHeaders);
      }
    } else {
      if (this.flexGrid && this.flexGrid.rowHeaders && this.flexGrid.rowHeaders.columns) {
        this.flexGrid.headersVisibility = wjGrid.HeadersVisibility.Column;
        this.flexGrid.headersFocusability = wjGrid.HeadersFocusability.Column;
        this.flexGrid.select(new wjGrid.CellRange(-0, 1), true, this.flexGrid.columnHeaders);
      }
    }
  }

  getReorderingFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/METRIC_ORDERING', '')
      .toPromise()
      .then((result) => {
        return result['METRIC_ORDERING'];
      });
  }

  tableOrGraphChange(isDataTableOrGraph) {
    this.isDataTableOrGraph = isDataTableOrGraph;
    this.processedBaseTableData = this.peercheckMessageHandlerService.processDataForBaseTableGrid(
      this.metricSelectionResponse,
      this.selectedMetric,
      null,
      this.groupBy,
      this.userSettingsModel,
      this.pcViewCols,
      this.firmBasicData,
      this.isDataTableOrGraph == 'Graph'
    );
  }

  onDraggingRow(s: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    //to add metric name in drag box
    this.selectedRowElement = document.getElementsByClassName('wj-rowheaders')[0].childNodes[e.row] as HTMLElement;

    const spanEle = document.createElement('span');
    spanEle.style.marginLeft = '10px';
    if (s && s.collectionView) {
      spanEle.innerHTML = s.collectionView.currentItem['metricName'];
    }
    const iEle = document.createElement('i');
    iEle.style.marginLeft = '-15px';
    iEle.style.padding = '20px';
    iEle.style.paddingRight = '22px';
    iEle.style.borderRight = '1px solid #d0d0d0';
    iEle.classList.add('bento-icon-drag-drop');
    this.selectedRowElement.children[0].classList.remove('bento-icon-drag-drop');
    this.selectedRowElement.childNodes[0].appendChild(iEle);
    this.selectedRowElement.childNodes[0].appendChild(spanEle);

    this.dragIndex = e.row;
    s.collectionView.moveCurrentToPosition(this.dragIndex);
  }

  onDraggedRow(s: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    let dropIndex = e.row,
      arr = s.collectionView.sourceCollection;

    s.collectionView.deferUpdate(() => {
      let item = arr[this.dragIndex];
      arr.splice(this.dragIndex, 1);
      arr.splice(dropIndex, 0, item);
      this.metricIdArr = arr.map((obj) => {
        return obj.metricId;
      });

      this.dragDone.emit({dragIndex: this.dragIndex, dropIndex, metricIdArr: this.metricIdArr});
      s.collectionView.moveCurrentToPosition(dropIndex);
    });
  }

  getDefaultViewDetails() {
    this.isDataLoading = true;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/view/firm/' + this.selectedFirmId + '/default', '')
      .subscribe(
        (result) => {
          this.viewDetails = result;
          this.userSettingsModel = this.peercheckMessageHandlerService.processSelectedViewDetails(
            this.viewDetails,
            this.userSettingsModel,
            this.firmBasicData.yyyymm
          );
          this.updateSelection();
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  generateViewDetails() {
    this.viewDetails = new ViewDetails();
    let requestbody: any = this.peercheckMessageHandlerService.createRequestBody(
      this.userSettingsModel,
      this.firmBasicData,
      'gas',
      false,
      null,
      null,
      this.shouldCheckMetricGroup
    );

    let mertics = [];
    for (let j = 0; j < this.metricIdArr.length; j++) {
      for (let i = 0; i < this.firmBasicData.metrics.length; i++) {
        if (this.firmBasicData.metrics[i].metricId == this.metricIdArr[j]) {
          mertics.push({id: this.firmBasicData.metrics[i].metricId, name: this.firmBasicData.metrics[i].name});
        }
      }
    }
    this.viewDetails.metrics = mertics;
    let peerGroups = [];
    for (let i = 0; i < requestbody.peerGroups.length; i++) {
      peerGroups.push({id: requestbody.peerGroups[i]});
    }
    this.viewDetails.peerGroups = peerGroups;
    this.viewDetails.matterTypeSetting = this.userSettingsModel.viewsSettings.matterType;
    this.viewDetails.contractorsSetting = this.userSettingsModel.viewsSettings.contractorsType;
    this.viewDetails.currencySetting = this.userSettingsModel.viewsSettings.currencyType;
    this.viewDetails.statisticSetting = this.userSettingsModel.viewsSettings.statisticsType;
    this.viewDetails.comparisionSetting = this.userSettingsModel.viewsSettings.comparisonType;

    let offices = [];
    for (let i = 0; i < requestbody.offices.length; i++) {
      offices.push({id: requestbody.offices[i]});
    }
    this.viewDetails.offices = offices;

    let practices = [];
    for (let i = 0; i < requestbody.practices.length; i++) {
      practices.push({id: requestbody.practices[i]});
    }

    this.viewDetails.practices = practices;

    let titles = [];
    for (let i = 0; i < requestbody.titles.length; i++) {
      titles.push({id: requestbody.titles[i]});
    }
    this.viewDetails.titles = titles;

    let associateExpYears = [];
    for (let i = 0; i < requestbody.experienceYears.associateYears.length; i++) {
      associateExpYears.push({id: requestbody.experienceYears.associateYears[i]});
    }
    this.viewDetails.associateExpYears = associateExpYears;

    let partnerExpYears = [];
    for (let i = 0; i < requestbody.experienceYears.partnerYears.length; i++) {
      partnerExpYears.push({id: requestbody.experienceYears.partnerYears[i]});
    }
    this.viewDetails.partnerExpYears = partnerExpYears;

    let srAssociateExpYears = [];
    for (let i = 0; i < requestbody.experienceYears.srAssociateYears.length; i++) {
      srAssociateExpYears.push({id: requestbody.experienceYears.srAssociateYears[i]});
    }
    this.viewDetails.srAssociateExpYears = srAssociateExpYears;
  }

  openExportModal() {
    this.generateViewDetails();

    const modalRef = this.modalService.open(ExportModalComponent, {size: 'lg'});
    modalRef.componentInstance.triggeredFrom = 'Create/Edit View';
    modalRef.componentInstance.firmBasicData = this.firmBasicData;
    modalRef.componentInstance.viewDetails = this.viewDetails;
    modalRef.componentInstance.userSettingsModel = this.userSettingsModel;
    modalRef.componentInstance.firmId = this.getDefaultOrSelectedFirmId;
    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'Data saved Successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  getStartAndEndDate() {
    let requestbody = {startPeriod: null, endPeriod: null};
    if (this.userSettingsModel.timeRangeSelected == 'Rolling') {
      requestbody['startPeriod'] =
        this.userSettingsModel &&
        this.userSettingsModel.rollingTimeRangeSelectedItems &&
        this.userSettingsModel.rollingTimeRangeSelectedItems.startPeriod1;
      requestbody['endPeriod'] =
        this.userSettingsModel &&
        this.userSettingsModel.rollingTimeRangeSelectedItems &&
        this.userSettingsModel.rollingTimeRangeSelectedItems.endPeriod1;
    } else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const startDate = new Date();
      const endDate = new Date();
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      startDate.setDate(1);
      endDate.setDate(1);
      endDate.setFullYear(parseInt(year));
      endDate.setMonth(parseInt(month) - 1);
      startDate.setFullYear(parseInt(year));
      requestbody['startPeriod'] = startDate.getFullYear() + '01';
      requestbody['endPeriod'] = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2);
    } else {
      requestbody['startPeriod'] =
        this.userSettingsModel &&
        this.userSettingsModel.fixedTimeRange &&
        this.userSettingsModel.fixedTimeRange.startPeriod1;
      requestbody['endPeriod'] =
        this.userSettingsModel &&
        this.userSettingsModel.fixedTimeRange &&
        this.userSettingsModel.fixedTimeRange.endPeriod1;
    }
    return requestbody;
  }

  async ngOnInit() {
    const requestBody = this.getStartAndEndDate();
    let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(requestBody.endPeriod, requestBody.startPeriod);
    this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
    if (this.route.url) {
      this.route.url.subscribe((val) => {
        let routePath = '';
        // for (let i = 0; i < val.length; i++) {
        if (val && val != null && val.length > 0) {
          routePath = val[val.length - 1].path;
        }
        if (routePath == 'create-view') {
          this.showExportButton = true;
          if (this.reorder) {
            this.flexGrid.rowHeaders.columns[0].width = 60;
            this.flexGrid.rowHeaders.columns[0].cssClass = 'cell-border bento-icon-drag-drop';
            this.flexGrid.headersVisibility = wjGrid.HeadersVisibility.All;
            this.flexGrid.headersFocusability = wjGrid.HeadersFocusability.All;
          } else {
            if (this.flexGrid) {
              this.flexGrid.headersVisibility = wjGrid.HeadersVisibility.Column;
              this.flexGrid.headersFocusability = wjGrid.HeadersFocusability.Column;
            }
          }
          // break;
        } else {
          if (this.flexGrid) {
            this.flexGrid.headersVisibility = wjGrid.HeadersVisibility.Column;
            this.flexGrid.headersFocusability = wjGrid.HeadersFocusability.Column;
          }
          this.showExportButton = false;
        }
        // }
      });
    }
    this.isColumnFreezed = true;
    this.frozenColumnCount = 3;
    this.userSettingsModel.isUKFirm = this.firmBasicData.defaultAccess == 'UK';
    this.userSettingsModel.isCANFirm = this.firmBasicData.defaultAccess == 'CAN';
    this.customOverlay = environment.customOverlay;
    this.selectedTimeRange = this.userSettingsModel.timeRangeSelected;

    let subscriptionFirm = this._service.isFirmChangedMessage.subscribe((isFirmChangedMessage: any) => {
      this.selectedFirmId = isFirmChangedMessage;
    });
    this.updateSummary();
    store.subscribe((state) => {
      if (state.processedMainGridData) this.processedMainGridData = state.processedMainGridData;
    });

    eventDispatcher.next({type: ActionTypes.GetProcessedData});
    this.onWindowClick();
    //this.getDefaultViewDetails();
    this.showReorderCheckbox = await this.getReorderingFeatureFlag();
    this.allowCopyPaste = await this.allowCopyFeatureFlag();
  }

  allowCopyFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/GRID_COPY_PASTE', '')
      .toPromise()
      .then((result) => {
        return result['GRID_COPY_PASTE'];
      });
  }

  onWindowClick() {
    window.addEventListener('click', (e) => {
      if (!this.gridClickFlag) {
        if (
          this.flexGrid &&
          this.flexGrid != null &&
          this.flexGrid.collectionView &&
          this.flexGrid.collectionView != null &&
          this.flexGrid.collectionView.currentItem &&
          this.flexGrid.collectionView.currentItem != null
        )
          this.flexGrid.collectionView.currentItem = null;
      } else {
        this.gridClickFlag = false;
      }

      if (!this.transposeGridClickFlag) {
        if (this.transposedGrid && this.transposedGrid.collectionView) {
          this.transposedGrid.collectionView.currentItem = null;
        }
      } else {
        this.transposeGridClickFlag = false;
      }
    });
  }

  getFirmLevelRules() {
    // get firm-level rules for the peer check view
    this.pcViewCols = this._service.getFirmLevelRules();
  }

  private get getDefaultfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  // getDefaultOrSelectedFirmId() should return the selected firmID OR firmID of logged in user.
  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (this.selectedFirmId) {
      return this.selectedFirmId;
    } else if (backendTokenClaims.userDDO && !this.route.snapshot.params.id) {
      return backendTokenClaims.userDDO.firmID;
    } else if (this.route.snapshot.params.id) {
      return parseInt(this.route.snapshot.paramMap.get('id'));
    } else {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  async setInputValues() {
    this.availableInputValues = await this.populateInputValues();
  }

  populateInputValues() {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/view/values/' + this.getDefaultOrSelectedFirmId, '')
      .toPromise()
      .then(
        (result) => {
          this.availableInputValues = result;
          return this.availableInputValues;
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
          return this.availableInputValues;
        }
      );
  }

  updateSelectedText() {
    if (this.userSettingsModel.metricSelectedItems && this.userSettingsModel.metricSelectedItems.length) {
      let selectedMetrics = this.userSettingsModel.metricSelectedItems.filter((obj) => {
        return obj.groupId != null;
      });
      if (selectedMetrics && selectedMetrics.length == 1)
        this.userSettingsModel.metricSelectedText = selectedMetrics.length + ' metric selected';
      else this.userSettingsModel.metricSelectedText = selectedMetrics.length + ' metrics selected';
    } else this.userSettingsModel.metricSelectedText = 'Select';

    if (this.userSettingsModel.peerGroupSelectedItems && this.userSettingsModel.peerGroupSelectedItems.length)
      if (this.userSettingsModel.peerGroupSelectedItems.length == 1)
        this.userSettingsModel.peerGroupSelectedText =
          this.userSettingsModel.peerGroupSelectedItems.length + ' peer group selected';
      else
        this.userSettingsModel.peerGroupSelectedText =
          this.userSettingsModel.peerGroupSelectedItems.length + ' peer groups selected';
    else this.userSettingsModel.peerGroupSelectedText = 'Select';

    if (this.userSettingsModel.practiceGroupSelectedItems && this.userSettingsModel.practiceGroupSelectedItems.length)
      if (this.userSettingsModel.practiceGroupSelectedItems.length == 1)
        this.userSettingsModel.practiceGroupSelectedText =
          this.userSettingsModel.practiceGroupSelectedItems.length + ' practice group selected';
      else
        this.userSettingsModel.practiceGroupSelectedText =
          this.userSettingsModel.practiceGroupSelectedItems.length + ' practice groups selected';
    else this.userSettingsModel.practiceGroupSelectedText = 'Select';

    if (this.userSettingsModel.officeSelectedItems && this.userSettingsModel.officeSelectedItems.length)
      if (this.userSettingsModel.officeSelectedItems.length == 1)
        this.userSettingsModel.officeSelectedText =
          this.userSettingsModel.officeSelectedItems.length + ' office selected';
      else
        this.userSettingsModel.officeSelectedText =
          this.userSettingsModel.officeSelectedItems.length + ' offices selected';
    else this.userSettingsModel.officeSelectedText = 'Select';

    if (this.userSettingsModel.titleSelectedItems && this.userSettingsModel.titleSelectedItems.length)
      if (this.userSettingsModel.titleSelectedItems.length == 1)
        this.userSettingsModel.titleSelectedText = this.userSettingsModel.titleSelectedItems.length + ' title selected';
      else
        this.userSettingsModel.titleSelectedText =
          this.userSettingsModel.titleSelectedItems.length + ' titles selected';
    else this.userSettingsModel.titleSelectedText = 'Select';

    if (this.userSettingsModel.partnerYearSelectedItems && this.userSettingsModel.partnerYearSelectedItems.length)
      if (this.userSettingsModel.partnerYearSelectedItems.length == 1)
        this.userSettingsModel.partnerYearSelectedText =
          this.userSettingsModel.partnerYearSelectedItems.length + ' experience year selected ';
      else
        this.userSettingsModel.partnerYearSelectedText =
          this.userSettingsModel.partnerYearSelectedItems.length + ' experiences year selected ';
    else this.userSettingsModel.partnerYearSelectedText = 'Select';

    if (this.userSettingsModel.associateYearSelectedItems && this.userSettingsModel.associateYearSelectedItems.length)
      if (this.userSettingsModel.associateYearSelectedItems.length == 1)
        this.userSettingsModel.associateYearSelectedText =
          this.userSettingsModel.associateYearSelectedItems.length + ' experience year selected ';
      else
        this.userSettingsModel.associateYearSelectedText =
          this.userSettingsModel.associateYearSelectedItems.length + ' experiences year selected ';
    else this.userSettingsModel.associateYearSelectedText = 'Select';

    if (
      this.userSettingsModel.srAssociateYearSelectedItems &&
      this.userSettingsModel.srAssociateYearSelectedItems.length
    )
      if (this.userSettingsModel.srAssociateYearSelectedItems.length == 1)
        this.userSettingsModel.srAssociateYearSelectedText =
          this.userSettingsModel.srAssociateYearSelectedItems.length + ' experience year selected ';
      else
        this.userSettingsModel.srAssociateYearSelectedText =
          this.userSettingsModel.srAssociateYearSelectedItems.length + ' experiences year selected ';
    else this.userSettingsModel.srAssociateYearSelectedText = 'Select';

    this.updateSummary();
  }

  setCardTitle() {
    if (this.userSettingsModel.timeRangeSelected != null) {
      this.selectedTimeRange = this.userSettingsModel.timeRangeSelected;
    }

    if (
      !this.userSettingsModel.practiceGroupSelectedItems.length &&
      !this.userSettingsModel.officeSelectedItems.length &&
      !this.userSettingsModel.titleSelectedItems.length
    ) {
      this.userSettingsModel.cardTitles = [];
    } else {
      let finalTitles = [];
      let finalOffices = [];
      if (this.availableInputValues && this.availableInputValues.titles) {
        finalTitles = this.peercheckMessageHandlerService.getSelectedOptionsByGroupIds(
          this.firmBasicData.titleGroups,
          this.userSettingsModel.titleSelectedItems,
          this.availableInputValues.titles,
          this.userSettingsModel.officeSelectedItems.length > 1 ||
            this.userSettingsModel.practiceGroupSelectedItems.length > 1
            ? true
            : false
        );
      } else {
        finalTitles = this.userSettingsModel.titleSelectedItems;
      }
      if (this.availableInputValues && this.availableInputValues.offices) {
        finalOffices = this.peercheckMessageHandlerService.getSelectedOptionsByGroupIds(
          this.firmBasicData.officeGroups,
          this.userSettingsModel.officeSelectedItems,
          this.availableInputValues.offices,
          this.userSettingsModel.titleSelectedItems.length > 1 ||
            this.userSettingsModel.practiceGroupSelectedItems.length > 1
            ? true
            : false
        );
      } else {
        finalOffices = this.userSettingsModel.officeSelectedItems;
      }

      let result = this.cartesian([
        {practice: this.userSettingsModel.practiceGroupSelectedItems},
        {office: finalOffices ? finalOffices : this.userSettingsModel.officeSelectedItems},
        {title: finalTitles ? finalTitles : this.userSettingsModel.titleSelectedItems},
        {associateYears: this.userSettingsModel.associateYearSelectedItems},
        {srAssociateYears: this.userSettingsModel.srAssociateYearSelectedItems},
        {partnerYears: this.userSettingsModel.partnerYearSelectedItems},
      ]);

      for (var i = result.length - 1; i >= 0; i--) {
        var cardTitle = '';
        var card: CardTitles = result[i];
        var isAlreadyPresent = false;

        if (card.practice) cardTitle = cardTitle + card.practice.name;
        if (card.office) cardTitle = (cardTitle ? cardTitle + ' / ' : '') + card.office.name;
        if (card.title) cardTitle = (cardTitle ? cardTitle + ' / ' : '') + card.title.name;

        if (card.associateYears && card.title && this.allowedAssociateYearTitles.indexOf(card.title.id) > -1)
          cardTitle = (cardTitle ? cardTitle + ' / ' : '') + card.associateYears.name;
        else if (card.associateYears) delete card['associateYears'];

        if (card.srAssociateYears && card.title && this.allowedSrAssociateYearTitles.indexOf(card.title.id) > -1)
          cardTitle = (cardTitle ? cardTitle + ' / ' : '') + card.srAssociateYears.name;
        else if (card.srAssociateYears) delete card['srAssociateYears'];

        if (card.partnerYears && card.title && this.allowedPartnerYearTitles.indexOf(card.title.id) > -1)
          cardTitle = (cardTitle ? cardTitle + ' / ' : '') + card.partnerYears.name;
        else if (card.partnerYears) delete card['partnerYears'];

        isAlreadyPresent = this.containsObject(cardTitle, result);

        if (isAlreadyPresent) {
          result.splice(i, 1);
        }

        card.cardTitle = cardTitle;
        card.isCollapsed = true;
        card.cardId = i;
        card.isExpanded = false;
      }
      this.userSettingsModel.cardTitles = result;
    }

    this.displayDescriptionList();
  }

  displayDescriptionList() {
    this.userSettingsModel.descriptionList = [];
    let selectedPractice = '';
    let selectedOffice = '';
    let selectedTitle = '';
    let selectedExperienceYear = '';
    if (this.userSettingsModel.practiceGroupSelectedItems.length > 0) {
      selectedPractice = this.peercheckMessageHandlerService.getCommaSeparatedString(
        this.userSettingsModel.practiceGroupSelectedItems,
        true
      );
    }
    if (this.userSettingsModel.officeSelectedItems.length > 0) {
      selectedOffice = this.peercheckMessageHandlerService.getCommaSeparatedString(
        this.userSettingsModel.officeSelectedItems,
        true
      );
    }
    if (this.userSettingsModel.titleSelectedItems.length > 0) {
      selectedTitle = this.peercheckMessageHandlerService.getCommaSeparatedString(
        this.userSettingsModel.titleSelectedItems,
        true
      );
    }
    if (this.userSettingsModel.associateYearSelectedItems && this.userSettingsModel.associateYearSelectedItems.length) {
      selectedExperienceYear = this.peercheckMessageHandlerService.getCommaSeparatedString(
        this.userSettingsModel.associateYearSelectedItems,
        true
      );
      if (
        (this.userSettingsModel.srAssociateYearSelectedItems &&
          this.userSettingsModel.srAssociateYearSelectedItems.length) ||
        (this.userSettingsModel.partnerYearSelectedItems && this.userSettingsModel.partnerYearSelectedItems.length)
      ) {
        selectedExperienceYear += ', ';
      }
    }
    if (
      this.userSettingsModel.srAssociateYearSelectedItems &&
      this.userSettingsModel.srAssociateYearSelectedItems.length
    ) {
      selectedExperienceYear += this.peercheckMessageHandlerService.getCommaSeparatedString(
        this.userSettingsModel.srAssociateYearSelectedItems,
        true
      );
      if (this.userSettingsModel.partnerYearSelectedItems && this.userSettingsModel.partnerYearSelectedItems.length) {
        selectedExperienceYear += ', ';
      }
    }
    if (this.userSettingsModel.partnerYearSelectedItems && this.userSettingsModel.partnerYearSelectedItems.length) {
      selectedExperienceYear += this.peercheckMessageHandlerService.getCommaSeparatedString(
        this.userSettingsModel.partnerYearSelectedItems,
        true
      );
    }
    if (this.userSettingsModel.timeRangeSelected == 'Rolling') {
      this.userSettingsModel.fixedTimeRange = new FixedTimeRange();
      if (
        this.userSettingsModel.rollingTimeRangeSelectedItems &&
        this.userSettingsModel.rollingTimeRangeSelectedItems.name
      ) {
        this.userSettingsModel.descriptionList.push({
          header: 'Date: ',
          value: this.userSettingsModel.rollingTimeRangeSelectedItems.name,
        });
      }
    } else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      const date = new Date();
      date.setDate(1);
      date.setFullYear(parseInt(year));
      date.setMonth(parseInt(month) - 1);
      const endDateText = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
      this.userSettingsModel.descriptionList.push({
        header: 'Date: ',
        value: 'Year to date (01/' + date.getFullYear() + ' through ' + endDateText + ')',
      });
    } else {
      this.userSettingsModel.rollingTimeRangeSelectedItems = null;
      if (this.userSettingsModel.fixedTimeRange.startDateText && this.userSettingsModel.fixedTimeRange.endDateText) {
        this.userSettingsModel.descriptionList.push({
          header: 'Date: ',
          value:
            this.userSettingsModel.fixedTimeRange.startDateText +
            ' through ' +
            this.userSettingsModel.fixedTimeRange.endDateText,
        });
      }
    }
    if (selectedPractice)
      this.userSettingsModel.descriptionList.push({header: 'Practice Group: ', value: selectedPractice});
    if (selectedOffice) this.userSettingsModel.descriptionList.push({header: 'Offices: ', value: selectedOffice});
    if (selectedTitle) this.userSettingsModel.descriptionList.push({header: 'Titles: ', value: selectedTitle});
    if (selectedExperienceYear)
      this.userSettingsModel.descriptionList.push({header: 'Experience: ', value: selectedExperienceYear});
  }

  openViewSettings() {
    const persistValues = this.userSettingsModel.viewsSettings;
    const modalRef = this.modalService.open(ViewsSettingsComponent, {ariaLabelledBy: 'modalSettings'});
    let viewSettings = {};
    viewSettings['requestFrom'] = 'openViewSettings';
    viewSettings['previousSettings'] = persistValues;
    modalRef.componentInstance.settingsModel = this.userSettingsModel.viewsSettings;
    modalRef.componentInstance.rules = this.pcViewCols;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.userSettingsModel.viewsSettings = result;
          if (
            persistValues.matterType != result.matterType ||
            persistValues.contractorsType != result.contractorsType ||
            persistValues.currencyType != result.currencyType
          ) {
            viewSettings['currentSettings'] = result;
            this.updateSelection(viewSettings);
          } else {
            this.alerts.push({
              type: 'success',
              msg: 'Settings applied successfully.',
              timeout: 2500,
              closeable: true,
            });
          }
          this.refreshSettingsFor(
            this.processedMainGridData,
            this.processedBaseTableData,
            this.mainGridBaseFlexGrid,
            this.mainGridFlexChart,
            null
          );
          this.userSettingsModel.cardTitles.forEach((card: CardTitles) => {
            this.refreshSettingsFor(
              card.processedMainGrid,
              card.processedBaseTable,
              card.basicFlexGrid,
              card.basicChart,
              card
            );
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: 'Unable to apply settings.',
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  collapseAll() {
    this.userSettingsModel.cardTitles.forEach((card) => {
      card.isCollapsed = true;
    });
  }

  expandAll() {
    this.userSettingsModel.cardTitles.forEach((card) => {
      card.isCollapsed = false;
    });
  }

  clearAll() {
    this.isInputPanelDataLoading = true;
    setTimeout(() => {
      this.userSettingsModel.fixedTimeRange = new FixedTimeRange();
      this.userSettingsModel.rollingTimeRangeSelectedItems = null;
      this.userSettingsModel.cardTitles = [];
      this.userSettingsModel.metricSelectedItems = null;
      this.userSettingsModel.peerGroupSelectedItems = [];
      this.userSettingsModel.practiceGroupSelectedItems = [];
      this.userSettingsModel.officeSelectedItems = [];
      this.userSettingsModel.titleSelectedItems = [];
      this.userSettingsModel.associateYearSelectedItems = [];
      this.userSettingsModel.srAssociateYearSelectedItems = [];
      this.userSettingsModel.partnerYearSelectedItems = [];
      this.isPartnerYear = false;
      this.isAssociateYear = false;
      this.isSrAssociateYear = false;
      this.userSettingsModel.descriptionList = [];
      this.disableDropdowns = true;
      this.userSettingsModel.timeRangeSelected = this.selectedTimeRange;
      this.isInputPanelDataLoading = false;
      if (this.peercheckMessageHandlerService) {
        this.processedMainGridData = this.peercheckMessageHandlerService.processDataForMainGrid(
          this.emptyGridObj,
          this.userSettingsModel
        );
      }
    }, 5);
  }

  handleMetricSelection(selectedOption) {
    this.selectedOption = selectedOption;
    this.metricData = this.firmBasicData.metrics;
    this.firmRules = this.firmBasicData.rules;
    this.isDataTableOrGraph = 'Grid';
    this.showDetailsTable = false;
    this.showBasicTable = true;
    let metric: Metric = new Metric();
    metric.id = selectedOption.metricId;
    metric.name = selectedOption.metricName;
    this.selectedMetric = metric;

    let metricData = this.metricData.filter((element) => element.metricId == this.selectedMetric.id);
    this.has_levels = metricData[0].hasLevels;
    this.report_type = metricData[0].reportType;
    this.can_pivot = metricData[0].canPivot;

    let metricMetaData = this.firmBasicData.metrics.find((obj) => obj.metricId == this.selectedMetric.id);
    if (!metricMetaData) {
      return;
    }

    // get metric level rules for selected metric
    let pcViewCols = JSON.parse(JSON.stringify(this.pcViewCols));
    this.metricBasicData = this._service.getColVisibilityForSelectedMetric(
      this.selectedMetric.id,
      pcViewCols,
      this.firmBasicData
    );

    let requestbody: any = this.peercheckMessageHandlerService.createRequestBody(
      this.userSettingsModel,
      this.firmBasicData,
      'line',
      true,
      this.selectedMetric,
      null,
      this.shouldCheckMetricGroup
    );

    if (!requestbody) {
      this.showBasicTable = false;
      return;
    }

    this.isDataLoading = true;
    this.updateDataLoadingState(this.isDataLoading);
    this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getDefaultOrSelectedFirmId, requestbody)
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
          if (metricMetaData) {
            let allowed_graphs = metricMetaData.allowedGraphs.map((s) => String.prototype.trim.apply(s));
            this.isLineChartAvailable = allowed_graphs.includes('line');
          } else {
            this.showBasicTable = false;
          }
          // PROCESS API RESPONSE
          if (this.peercheckMessageHandlerService && result) {
            let groupBy = requestbody.groupBy && requestbody.groupBy.length ? requestbody.groupBy[0] : 'yyyymm';
            this.groupBy = groupBy;
            let response = Array.isArray(result) && result.length == 1 ? result[0] : result;
            this.metricSelectionResponse = response;
            this.processedBaseTableData = this.peercheckMessageHandlerService.processDataForBaseTableGrid(
              response,
              this.selectedMetric,
              null,
              groupBy,
              this.userSettingsModel,
              this.pcViewCols,
              this.firmBasicData
            );
          }
        },
        (error) => {
          this.showBasicTable = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
        }
      );
  }

  cartesian(array) {
    function c(part, index, allowedPartnerYearTitles, allowedAssociateYearTitles, allowedSrAssociateYearTitles) {
      var k = Object.keys(array[index])[0];
      if (array[index][k].length > 0) {
        array[index][k].forEach(function (a, i) {
          var p = Object.assign({}, part, {[k]: a});
          if (index + 1 === array.length) {
            r.push(p);
            return;
          }
          c(p, index + 1, allowedPartnerYearTitles, allowedAssociateYearTitles, allowedSrAssociateYearTitles);
        });
      } else {
        if (index + 1 === array.length) {
          r.push(part);
          return;
        }
        c(part, index + 1, allowedPartnerYearTitles, allowedAssociateYearTitles, allowedSrAssociateYearTitles);
      }
    }
    var r = [];
    c({}, 0, this.allowedPartnerYearTitles, this.allowedAssociateYearTitles, this.allowedSrAssociateYearTitles);
    return r;
  }

  containsObject(cardTitle, result) {
    var i;
    for (i = 0; i < result.length; i++) {
      if (result[i].cardTitle === cardTitle) {
        return true;
      }
    }

    return false;
  }

  createQueryParameter(type: string, isMetricSelection: boolean = false, card: CardTitles = null) {
    let requestbody = {};
    requestbody['quid'] = uuidv4();
    if (
      this.firmBasicData &&
      ((this.userSettingsModel.timeRangeSelected == 'Rolling' &&
        this.userSettingsModel.rollingTimeRangeSelectedItems &&
        this.userSettingsModel.rollingTimeRangeSelectedItems.name) ||
        (this.userSettingsModel.timeRangeSelected == 'Fixed' &&
          this.userSettingsModel.fixedTimeRange.startDateText &&
          this.userSettingsModel.fixedTimeRange.endDateText) ||
        this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') &&
      this.userSettingsModel.metricSelectedItems.length > 0 &&
      this.userSettingsModel.peerGroupSelectedItems.length > 0
    ) {
      //Add Metric
      let metrics = [];
      let result = this.userSettingsModel.metricSelectedItems.filter((item) => item.groupId != null);
      if (isMetricSelection && this.selectedMetric && !card) {
        metrics.push(this.selectedMetric.id);
      } else if (isMetricSelection && card && card.selectedMetric) {
        metrics.push(card.selectedMetric.id);
      } else {
        for (let i = 0; i < result.length; i++) {
          metrics.push(result[i].id);
        }
      }
      requestbody['metrics'] = metrics;

      let sDateText: string = null;
      let eDateText: string = null;

      //Time Range
      if (this.userSettingsModel.timeRangeSelected == 'Rolling') {
        sDateText = this.userSettingsModel.rollingTimeRangeSelectedItems.startPeriod1;
        eDateText = this.userSettingsModel.rollingTimeRangeSelectedItems.endPeriod1;
        requestbody['startPeriod'] = this.userSettingsModel.rollingTimeRangeSelectedItems.startPeriod1;
        requestbody['endPeriod'] = this.userSettingsModel.rollingTimeRangeSelectedItems.endPeriod1;
      } else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
        const startDate = new Date();
        const endDate = new Date();
        sDateText = startDate.getFullYear() + '01';
        eDateText = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2);
        requestbody['startPeriod'] = startDate.getFullYear() + '01';
        requestbody['endPeriod'] = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2);
      } else {
        sDateText = this.userSettingsModel.fixedTimeRange.startPeriod1;
        eDateText = this.userSettingsModel.fixedTimeRange.endPeriod1;
        requestbody['startPeriod'] = this.userSettingsModel.fixedTimeRange.startPeriod1;
        requestbody['endPeriod'] = this.userSettingsModel.fixedTimeRange.endPeriod1;
      }

      if (this.userSettingsModel.peerGroupSelectedItems) {
        let peerGroups: number[] = [...new Set(this.userSettingsModel.peerGroupSelectedItems.map((item) => item.id))];
        requestbody['peerGroups'] = peerGroups;
      } else {
        requestbody['peerGroups'] = [];
      }

      if (card) {
        if ('practice' in card && card.practice && card.practice.id) {
          let practices: number[] = [];
          practices.push(card.practice.id);
          requestbody['practices'] = practices;
        } else {
          requestbody['practices'] = [];
        }

        if ('office' in card && card.office && card.office.id) {
          let offices: number[] = [];
          offices.push(card.office.id);
          requestbody['offices'] = offices;
        } else {
          requestbody['offices'] = [];
        }

        if ('title' in card && card.title && card.title.id) {
          let titles: number[] = [];
          titles.push(card.title.id);
          requestbody['titles'] = titles;
        } else {
          requestbody['titles'] = [];
        }

        let experienceYears = {};

        if ('partnerYears' in card && card.partnerYears && card.partnerYears.id) {
          let partnerYears: number[] = [];
          partnerYears.push(card.partnerYears.id);
          experienceYears['partnerYears'] = partnerYears;
        } else {
          experienceYears['partnerYears'] = [];
        }

        if ('srAssociateYears' in card && card.srAssociateYears && card.srAssociateYears.id) {
          let srAssociateYears: number[] = [];
          srAssociateYears.push(card.srAssociateYears.id);
          experienceYears['srAssociateYears'] = srAssociateYears;
        } else {
          experienceYears['srAssociateYears'] = [];
        }

        if ('associateYears' in card && card.associateYears && card.associateYears.id) {
          let associateYears: number[] = [];
          associateYears.push(card.associateYears.id);
          experienceYears['associateYears'] = associateYears;
        } else {
          experienceYears['associateYears'] = [];
        }
        requestbody['experienceYears'] = experienceYears;
      } else {
        if (this.userSettingsModel.practiceGroupSelectedItems) {
          let practices: number[] = [
            ...new Set(this.userSettingsModel.practiceGroupSelectedItems.map((item) => item.id)),
          ];
          requestbody['practices'] = practices;
        } else {
          requestbody['practices'] = [];
        }

        if (this.userSettingsModel.officeSelectedItems) {
          let offices: number[] = [...new Set(this.userSettingsModel.officeSelectedItems.map((item) => item.id))];
          let parents: number[] = offices.filter((id) => id < 0);
          let child: number[] = offices.filter((id) => id >= 0);
          offices = this.peercheckMessageHandlerService.getExtractGroupIds(
            this.firmBasicData.officeGroups,
            offices,
            parents,
            child
          );
          requestbody['offices'] = offices;
        } else {
          requestbody['offices'] = [];
        }

        if (this.userSettingsModel.titleSelectedItems) {
          let titles: number[] = [...new Set(this.userSettingsModel.titleSelectedItems.map((item) => item.id))];
          let parents: number[] = titles.filter((id) => id < 0);
          let child: number[] = titles.filter((id) => id >= 0);
          titles = this.peercheckMessageHandlerService.getExtractGroupIds(
            this.firmBasicData.titleGroups,
            titles,
            parents,
            child
          );
          requestbody['titles'] = titles;
        } else {
          requestbody['titles'] = [];
        }

        let experienceYears = {};

        if (this.userSettingsModel.partnerYearSelectedItems) {
          let partnerYears: number[] = [
            ...new Set(this.userSettingsModel.partnerYearSelectedItems.map((item) => item.id)),
          ];
          experienceYears['partnerYears'] = partnerYears;
        } else {
          experienceYears['partnerYears'] = [];
        }

        if (this.userSettingsModel.srAssociateYearSelectedItems) {
          let srAssociateYears: number[] = [
            ...new Set(this.userSettingsModel.srAssociateYearSelectedItems.map((item) => item.id)),
          ];
          experienceYears['srAssociateYears'] = srAssociateYears;
        } else {
          experienceYears['srAssociateYears'] = [];
        }

        if (this.userSettingsModel.srAssociateYearSelectedItems) {
          let associateYears: number[] = [
            ...new Set(this.userSettingsModel.associateYearSelectedItems.map((item) => item.id)),
          ];
          experienceYears['associateYears'] = associateYears;
        } else {
          experienceYears['associateYears'] = [];
        }
        requestbody['experienceYears'] = experienceYears;
      }

      // Dependency data from Basic details
      requestbody['yyyymm'] = this.firmBasicData.yyyymm;
      requestbody['currency'] = this.firmBasicData.currency;
      requestbody['firmCurrency'] = this.firmBasicData.firmCurrency;
      requestbody['defaultAccess'] = this.firmBasicData.defaultAccess;

      // add query parameter for Matter type
      requestbody['matterType'] = this.userSettingsModel.viewsSettings.matterType;

      // add query parameter for Contractor
      requestbody['contractors'] = this.userSettingsModel.viewsSettings.contractorsType;

      // add query parameter for Currency
      requestbody['currencyType'] = this.userSettingsModel.viewsSettings.currencyType;

      requestbody['log'] = true;

      // if (type == 'pivot') requestbody['groupBy'] = ['practice'];

      requestbody['type'] = type;

      // Add query for Grid or Line
      if (type == 'line') {
        // Add query for grouping by time range
        let start_Date = new Date();
        let end_Date = new Date();
        if (sDateText.length == 6 && eDateText.length == 6) {
          let start_year: number = parseInt(sDateText.slice(0, 4));
          let start_month: number = parseInt(sDateText.slice(4, 6));
          start_Date.setFullYear(start_year, start_month - 1);

          let end_year: number = parseInt(eDateText.slice(0, 4));
          let end_month: number = parseInt(eDateText.slice(4, 6));
          end_Date.setFullYear(end_year, end_month - 1);
          let diffOfYears = this.peercheckMessageHandlerService.diffOfYears(end_Date, start_Date);

          if (diffOfYears > 1) {
            let groupBy = [];
            groupBy.push('quarter');
            requestbody['groupBy'] = groupBy;
            requestbody['overFullQuarter'] = true;
          } else {
            let groupBy = [];
            groupBy.push('yyyymm');
            requestbody['groupBy'] = groupBy;
          }
        }
      }
      requestbody['type'] = type;

      return requestbody;
    } else {
      return null;
    }
  }

  onInitialized(grid, card: CardTitles = null, isManualCall: boolean = false, gridHeaderId: string = '') {
    if (!grid || !grid.columnHeaders) return;
    grid.addEventListener(grid.hostElement, 'click', (e) => {
      let ht = grid.hitTest(e);
      if (ht.row != -1 && ht.col != -1) {
        this.gridClickFlag = true;
      }
    });
    grid.hostElement.addEventListener(
      'keydown',
      (e) => {
        this.lastPressedKey = e.code;
        if (card) {
          this.handleKeyDown(grid, true, e);
        } else {
          this.handleKeyDown(grid, false, e);
        }
      },
      true
    );
    // if(card){
    //   grid.loadedRow.addHandler(() => {
    //     grid.hostElement.removeAttribute("aria-hidden")
    //   });
    // }

    grid.selectionChanged.addHandler((s, e) => {
      if (card != null) {
        this.handleSelectionChanged(s, true, e);
      } else {
        this.handleSelectionChanged(s, false, e);
      }
    });

    grid.gotFocus.addHandler((s, e) => {
      if (card != null) {
        this.focusOnGrid(s, true, e);
      } else {
        this.focusOnGrid(s, false, e);
      }
    });

    grid._useFrozenDiv = function () {
      return false;
    };

    // Delete first column from the grid
    if (grid && card == null && grid.rowHeaders && grid.rowHeaders.columns) {
      if (this.showExportButton && this.reorder) {
        grid.rowHeaders.columns[0].width = 60;
        grid.rowHeaders.columns[0].cssClass = 'cell-border bento-icon-drag-drop';
        grid.headersVisibility = wjGrid.HeadersVisibility.All;
        grid.headersFocusability = wjGrid.HeadersFocusability.All;
      } else {
        grid.headersVisibility = wjGrid.HeadersVisibility.Column;
        grid.headersFocusability = wjGrid.HeadersFocusability.Column;
      }
    }

    // create extra header row
    var extraRow = new wjGrid.Row();
    extraRow.height = 40;
    extraRow.allowMerging = true;

    // Fetch all column headers
    var panel = grid.columnHeaders;

    // Delete the previously created row
    if (isManualCall) {
      panel.rows.splice(0, panel.rows.length - 1);
    }

    // add extra header row to the grid
    panel.rows.splice(0, 0, extraRow);

    // Implemented error handling
    try {
      // set the custome header titles
      panel.setCellData(0, 1, 'My firm');
      panel.setCellData(0, 2, 'My firm');
      panel.setCellData(0, 0, null);

      let headers = null;
      let columnCount = 0;

      if (
        card &&
        card.processedMainGrid.processedColumn &&
        card.processedMainGrid.processedHeaders &&
        card.processedMainGrid.processedColumnCount
      ) {
        headers = card.processedMainGrid.processedHeaders;
        columnCount = card.processedMainGrid.processedColumnCount;
      } else {
        headers = this.processedMainGridData.processedHeaders;
        columnCount = this.processedMainGridData.processedColumnCount;
      }

      if (headers && columnCount) {
        // Used Timeout to get the panel ready to use
        setTimeout(() => {
          for (let colIndex = 1, counter = 0; colIndex <= headers.length * columnCount; colIndex++) {
            try {
              panel.setCellData(0, colIndex + 2, headers[counter]);
            } catch (error) {
              return;
            }
            counter += colIndex % columnCount == 0 ? 1 : 0;
          }
        }, 10);
      }

      grid.hostElement.setAttribute('role', 'grid');
      if (gridHeaderId) grid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
    } catch (e) {
      return;
    }
    //Handle default selection of wijmo Flex grid

    grid.refreshed.addHandler((s, e) => {
      let ch = s.hostElement.querySelectorAll('div [aria-hidden=true]');
      if (ch) {
        ch.forEach((e) => e.setAttribute('aria-hidden', false));
      }
    });

    //****************** */
    // center-align merged header cells
    let count = 1;
    grid.topLeftCells.columns.forEach(function (col) {
      // Merge Top left cell
      col.allowMerging = true;
    });
    grid.formatItem.addHandler((s: wjGrid.FlexGrid, e) => {
      if (e.panel == s.columnHeaders) {
        if (e.cell.innerText != 'My firm') {
          let headerText = e.panel.getCellData(e.row, e.col, true);
          let peerGroup = this.userSettingsModel.peerGroupSelectedItems.find((obj) => obj.name == headerText);

          if (headerText && peerGroup) {
            // e.cell.tabIndex = -1;
            e.cell.innerHTML = `
                <h2 class="h6 mb-0 DataGrid-heading">
                  <a
                    href=""
                    aria-haspopup="dialog"
                    id="${peerGroup.id}"
                    role="button"
                    aria-label="${peerGroup.name}"
                    data-pgid="${peerGroup.id}"
                    data-pgName="${peerGroup.name}"
                    tabindex="-1"
                  >
                    ${peerGroup.name}
                  </a>
                </h2>
              `;

            count++;
            let el = e.cell.querySelector('a');
            el.onclick = (et: any) => {
              et.preventDefault();
              let dataSet = et.target.dataset;
              this.modalTitle = et.target.ariaLabel;
              this.populateSelectedFirmsForPeerGroup(dataSet, et.target.ariaLabel);
            };
          } else if (headerText && !peerGroup && headerText != '') {
            if (e.row == 1) {
              let header1 = e.panel.getCellData(0, e.col);
              let header2 = e.panel.getCellData(e.row, e.col);
              let headerAriaLabel = header1 ? header1 + ' ' + header2 : header2;
              e.cell.setAttribute('aria-label', `${headerAriaLabel}`);
              let col = e.getColumn();
              e.cell.setAttribute('aria-colindex', col.visibleIndex);
            }
          }
        }
        if (e.row == 0) {
          e.cell.removeAttribute('role');
        }
      } else if ((e.row == 0 || e.row == 1) && e.cell.innerText && e.cell.classList.value.indexOf('wj-header') != -1) {
        e.cell.innerHTML = '<h2 class="h6 mb-0 DataGrid-heading">' + e.cell.innerText + '</h2>';
      } else if (e.panel.cellType == wjGrid.CellType.TopLeft) {
        e.cell.setAttribute('aria-label', 'Drag & drop column header/Select all');
        e.cell.setAttribute('aria-rowindextext', 'row 1');
        e.cell.setAttribute('aria-colindextext', ' ');
      } else {
        e.cell.setAttribute('role', 'gridcell');
      }
      if (e.row == 0 && e.col == 0 && e.cell.innerText == '' && e.cell.classList.value.indexOf('wj-header') != -1) {
        //wjcCore.addClass(e.cell, 'hideCellCreateView');
        if (this.fromCreateView) wjcCore.addClass(e.cell, 'hideCellCreateView');
        else wjcCore.addClass(e.cell, 'hideCell');
      }
      if (e.panel && e.panel.cellType == 3 && e.cell.classList.value.indexOf('bento-icon-drag-drop') != -1) {
        let item = e.getRow().dataItem;
        e.cell.setAttribute('aria-label', 'Drag handler: ' + item.metricName);
        let currentrow = e.row + 3;
        e.cell.setAttribute('aria-rowindextext', 'row ' + currentrow);
        e.cell.setAttribute('aria-colindextext', ' ');
      } else if (e.panel == s.cells) {
        let col = e.getColumn();
        if (col.binding == 'metricName') {
          let link = e.cell.querySelector('a');
          if (link) {
            let item = e.getRow().dataItem;
            if (item.metricName != null) {
              this.attachPopover(link, item, s);
            }
          }
        }
      }
      // set aria-colIndex, workaround for correct column number when some columns are hidden
      if (e.panel == s.cells) {
        let col = e.getColumn();
        e.cell.setAttribute('aria-colindex', col.visibleIndex);
      }
    });
    if (this.showExportButton && this.reorder) {
      grid.select(new wjGrid.CellRange(0, 0), true, grid.topLeftCells);
    } else {
      grid.select(new wjGrid.CellRange(-0, 1), true, grid.columnHeaders);
    }

    if (!isManualCall) {
      if (card != null) {
        card.mainFlexGrid = grid;
      } else {
        this.flexGrid = grid;
      }
    }
  }

  populateSelectedFirmsForPeerGroup(dataset: any, peerGroupName: string) {
    let peerGroup = null;
    if (dataset) {
      peerGroup = this.userSettingsModel.peerGroupSelectedItems.find(
        (obj) => obj.id == dataset.pgid || obj.name == dataset.pgname
      );
    } else {
      peerGroup = this.userSettingsModel.peerGroupSelectedItems.find((obj) => obj.name == peerGroupName);
    }
    if (peerGroup) {
      if (peerGroup.isPpg) {
        this._service.getCriteriaForPerformancePg(peerGroup, this.userSettingsModel.isCANFirm, this.isTAdmin);
      } else {
        this._service.getSelectedFirmsForPg(peerGroup);
      }
      let peerGroupDetail = this._service.peerGroupSelectedFirms.subscribe((peerGroupDetails: PeerGroupDetails) => {
        if (peerGroupDetails.hasError) {
          if (peerGroupDetails.alerts && peerGroupDetails.alerts.length) {
            this.alerts = peerGroupDetails.alerts;
          }
        } else {
          this.peerGroupDetail = peerGroupDetails.successResponse;
          if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
          }
          // Addded flexgrid refresh to detect changes in UI
          // TODO: find Root cause of changeDetection after MVP
          this.flexGrid.refresh();
          setTimeout(function () {
            $('.close').focus();
          }, 100);
          this.modalService.open(this.listOfFirmsModalContent, {ariaLabelledBy: 'modalPeerGroup'});
        }
      });
      this.subscription.push(peerGroupDetail);
    }
  }

  handleCardExpandCollapseAction(card: CardTitles) {
    card.isCollapsed = !card.isCollapsed;

    if (!card.isExpanded && !card.isCollapsed) {
      card.processedMainGrid = this.getEmptyGridData();
      let requestBody: any = this.peercheckMessageHandlerService.createRequestBody(
        this.userSettingsModel,
        this.firmBasicData,
        'gas',
        false,
        null,
        card,
        this.shouldCheckMetricGroup
      );
      if (!requestBody && !this.pcViewCols) {
        card.isCardProcessing = false;
        card.isExpanded = false;
        return;
      }

      card.isCardProcessing = true;
      card.isExpanded = true;
      this.service
        .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getDefaultOrSelectedFirmId, requestBody)
        .subscribe(
          (result) => {
            card.isCardProcessing = false;
            card.shouldShowBasicTable = false;
            card.isDataTableOrGraph = 'Grid';
            card.hasError = false;
            card.processedMainGrid = new ProcessedMainData();
            this.expandDataRaw = result;
            let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(
              requestBody.endPeriod,
              requestBody.startPeriod
            );
            this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
            // PROCESS API RESPONSE

            if (this.peercheckMessageHandlerService && result) {
              let response = Array.isArray(result) && result.length == 1 ? result[0] : result;
              card.processedMainGrid = this.peercheckMessageHandlerService.processDataForMainGrid(
                response,
                this.userSettingsModel,
                null,
                this.pcViewCols,
                this.firmBasicData,
                this.yoyHeader
              );
            }
            if (card.processedMainGrid.hasEmptyResult) {
              this.alerts.push({
                type: 'warning',
                msg: 'No data available to display.',
                closeable: true,
              });
            }
            this.onInitialized(card.mainFlexGrid, null, true);
          },
          (error) => {
            card.isCardProcessing = false;
            card.hasError = true;
            card.errorMessage = error.error;
            card.isExpanded = false;
            card.isCollapsed = true;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        );
    }
  }

  refreshSettingsFor(
    mainGridData: ProcessedMainData,
    baseGridData: ProcessedBaseTableData,
    baseTableFlexGrid: WjTransposedGrid | wjGrid.FlexGrid,
    flexChart: WjFlexChart,
    card: CardTitles
  ) {
    if (!mainGridData || !mainGridData.processedColumn || !mainGridData.processedColumnCount || !this.pcViewCols) {
      return;
    }
    mainGridData.processedColumn.forEach((col) => {
      let title: string = col && col.binding ? col.binding : '';
      if (title.endsWith('_mean')) col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Mean';
      else if (title.endsWith('_median')) col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Median';
      else if (title.endsWith('_mean_yoy'))
        col.visible =
          this.userSettingsModel.viewsSettings.comparisonType == 'year over year' &&
          this.userSettingsModel.viewsSettings.statisticsType == 'Mean';
      else if (title.endsWith('_median_yoy'))
        col.visible =
          this.userSettingsModel.viewsSettings.comparisonType == 'year over year' &&
          this.userSettingsModel.viewsSettings.statisticsType == 'Median';
      else if (title.endsWith('_median_yoy'))
        col.visible =
          this.userSettingsModel.viewsSettings.comparisonType == 'variance' &&
          this.userSettingsModel.viewsSettings.statisticsType == 'Mean';
      else if (title.endsWith('_mean_variance'))
        col.visible =
          this.userSettingsModel.viewsSettings.comparisonType == 'variance' &&
          this.userSettingsModel.viewsSettings.statisticsType == 'Mean';
      else if (title.endsWith('_median_variance'))
        col.visible =
          this.userSettingsModel.viewsSettings.comparisonType == 'variance' &&
          this.userSettingsModel.viewsSettings.statisticsType == 'Median';
      else if (title.endsWith('_mean_quartile'))
        col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Mean' && this.pcViewCols.showQuartile;
      else if (title.endsWith('_median_quartile'))
        col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Median' && this.pcViewCols.showQuartile;
    });

    if (baseGridData && baseGridData.processedColumn && baseTableFlexGrid) {
      baseGridData.processedColumn.forEach((col) => {
        let binding: string = col && col.binding ? col.binding : '';
        if (binding.indexOf('mean') > -1) {
          col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Mean';
        } else if (binding.indexOf('median') > -1) {
          col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Median';
        }
      });

      if (baseGridData && baseGridData.processedChartSeries && flexChart) {
        baseGridData.processedChartSeries.forEach((col) => {
          let binding: string = col && col.binding ? col.binding : '';
          if (binding.indexOf('mean') > -1) {
            col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Mean';
          } else if (binding.indexOf('median') > -1) {
            col.visible = this.userSettingsModel.viewsSettings.statisticsType == 'Median';
          }
        });
        flexChart.refresh(true);
      }

      if (card) {
        card.isCardProcessing = true;
        card.shouldShowBasicTable = false;
        card.showDetailsTable = false;
        setTimeout(() => {
          if (card.isDataTableOrGraph == '') card.isDataTableOrGraph = 'Grid';
          card.shouldShowBasicTable = true;
          card.isCardProcessing = false;
        }, 10);
      } else {
        this.showBasicTable = false;
        this.showDetailsTable = false;
        this.isDataLoading = true;
        this.updateDataLoadingState(this.isDataLoading);
        setTimeout(() => {
          if (this.isDataTableOrGraph == '') this.isDataTableOrGraph = 'Grid';
          this.showBasicTable = true;
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
        }, 10);
      }
    }
  }

  handleMetricSelectionFromCard(selectedMetric, card: CardTitles) {
    if (!selectedMetric) return;

    card.selectedOption = selectedMetric;
    //card.isDataTableOrGraph = 'Grid';
    card.showDetailsTable = false;
    card.showBasicTable = true;

    let metric: Metric = new Metric();
    metric.id = selectedMetric.metricId;
    metric.name = selectedMetric.metricName;
    card.selectedMetric = metric;

    let metricMetaData = this.firmBasicData.metrics.find((obj) => obj.metricId == card.selectedMetric.id);
    if (!metricMetaData) {
      return;
    }

    // let metricDataCard = this.metricData.filter((element) => element.metricId == card.selectedMetric.id);
    card.has_levels = metricMetaData.hasLevels;
    card.report_type = metricMetaData.reportType;
    card.can_pivot = metricMetaData.canPivot;

    // get metric level rules for selected metric
    let pcViewCols = JSON.parse(JSON.stringify(this.pcViewCols));
    card.metricBasicData = this._service.getColVisibilityForSelectedMetric(
      card.selectedMetric.id,
      pcViewCols,
      this.firmBasicData
    );

    let requestBody: any = this.peercheckMessageHandlerService.createRequestBody(
      this.userSettingsModel,
      this.firmBasicData,
      'line',
      true,
      card.selectedMetric,
      card,
      this.shouldCheckMetricGroup
    );
    if (!requestBody) {
      card.isCardProcessing = false;
      card.isCollapsed = true;
      return;
    }

    card.isCardProcessing = true;
    this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getDefaultOrSelectedFirmId, requestBody)
      .subscribe(
        (result) => {
          card.isCardProcessing = false;
          card.shouldShowBasicTable = true;
          if (metricMetaData) {
            let allowed_graphs = metricMetaData.allowedGraphs.map((s) => String.prototype.trim.apply(s));
            card.isLineChartAvailable = allowed_graphs.includes('line');
          } else {
            card.shouldShowBasicTable = false;
          }
          //card.isDataTableOrGraph = 'Grid';
          card.hasError = false;
          card.processedBaseTable = new ProcessedBaseTableData();
          // PROCESS API RESPONSE
          if (this.peercheckMessageHandlerService && result) {
            let response = Array.isArray(result) && result.length == 1 ? result[0] : result;
            let groupBy = requestBody.groupBy && requestBody.groupBy.length ? requestBody.groupBy[0] : 'yyyymm';

            var is_graph = false;
            if (card.isDataTableOrGraph == 'Graph') is_graph = true;

            card.processedBaseTable = this.peercheckMessageHandlerService.processDataForBaseTableGrid(
              response,
              card.selectedMetric,
              card,
              groupBy,
              this.userSettingsModel,
              this.pcViewCols,
              this.firmBasicData,
              is_graph
            );
          }
        },
        (error) => {
          card.isCardProcessing = false;
          card.shouldShowBasicTable = false;
          card.hasError = true;
          card.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  gridClick(selectedMetric, card) {
    card.isDataTableOrGraph = 'Grid';
    this.handleMetricSelectionFromCard(selectedMetric, card);
  }

  graphClick(selectedMetric, card) {
    card.isDataTableOrGraph = 'Graph';
    this.handleMetricSelectionFromCard(selectedMetric, card);
  }
  setDetails(card, cardIndex, details) {
    if (cardIndex > -1) {
      card.shouldShowBasicTable = false;
      card.showDetailsTable = true;
      card.isDetails = details;
      card.isDataTableOrGraph = '';
      this.pivotIndex = cardIndex.toString();
      this.setRequestParameterForPivot(details, card.report_type, card, card.selectedMetric, this.pivotIndex);
    } else {
      this.showBasicTable = false;
      this.showDetailsTable = true;
      this.isDetails = details;
      this.isDataTableOrGraph = '';
      this.pivotIndex = cardIndex.toString();
      this.setRequestParameterForPivot(details, this.report_type, null, this.selectedMetric, this.pivotIndex);
    }
  }

  setRequestParameterForPivot(details, report_type, card: null, selectedMetric, cardIndex) {
    if (details == 'Details' && report_type == 'STANDARD') {
      let requestBody = this.peercheckMessageHandlerService.createRequestBody(
        this.userSettingsModel,
        this.firmBasicData,
        'pivot',
        true,
        selectedMetric,
        card,
        false
      );
      if (!requestBody && !this.firmBasicData && !this.pcViewCols) {
        return;
      }

      this.requestBodyForPivot.requestBodyPractice = JSON.parse(JSON.stringify(requestBody));
      this.requestBodyForPivot.requestBodyOffice = JSON.parse(JSON.stringify(requestBody));
      this.requestBodyForPivot.requestBodyTitle = JSON.parse(JSON.stringify(requestBody));
      this.requestBodyForPivot.requestBodyOfficeGroup = JSON.parse(JSON.stringify(requestBody));
    } else if (details == 'Expand' && report_type == 'OFFICES') {
      let requestBodyExpand = this.peercheckMessageHandlerService.createRequestBody(
        this.userSettingsModel,
        this.firmBasicData,
        'pivot',
        true,
        selectedMetric,
        card,
        false
      );
      this.requestBodyForPivot.requestBodyExpandOfficeParent = JSON.parse(JSON.stringify(requestBodyExpand));
      this.requestBodyForPivot.requestBodyExpandOfficeChildren = JSON.parse(JSON.stringify(requestBodyExpand));
      this.requestBodyForPivot.requestBodyExpandAllOffices = JSON.parse(JSON.stringify(requestBodyExpand));
    } else if (details == 'Expand' && report_type == 'EXPANDED') {
      let requestBodyExpand = this.peercheckMessageHandlerService.createRequestBody(
        this.userSettingsModel,
        this.firmBasicData,
        'expand',
        true,
        selectedMetric,
        card,
        false
      );
      this.requestBodyForPivot.requestBodyExpandCategory = JSON.parse(JSON.stringify(requestBodyExpand));
      this.requestBodyForPivot.requestBodyExpandCategoryChildren = JSON.parse(JSON.stringify(requestBodyExpand));

      if (this.expandDataRaw) {
        this.expandData = JSON.parse(JSON.stringify(this.expandDataRaw[0]));

        this.expandData.values[0].metrics.forEach((element, indx) => {
          if (element.metricId == selectedMetric.id) {
            this.expandData.metrics = [this.expandData.values[0].metrics[indx]];
          }
        });
      }
    }

    this.offGroups = [
      ...new Set(this.availableInputValues.offices.filter((item) => item.id < 0).map((item) => Math.abs(item.id))),
    ];
    this.requestBodyForPivot.officeGroups = this.offGroups && this.offGroups.length ? this.offGroups : [];
    this.pivotRequestBody[cardIndex] = JSON.parse(JSON.stringify(this.requestBodyForPivot));
    eventDispatcher.next({type: ActionTypes.SetPivotRequestBody, payload: this.pivotRequestBody});
  }

  itemFormatter(_: any, label: {val: any; text: string; pos: Point; cls: string}) {
    // label.text = '$' + label.text;
    return label;
  }

  getRandomInt(max, strokeDasharray = []) {
    let number = Math.floor(Math.random() * max) + 1;
    if (strokeDasharray.includes(number)) {
      const range = max + 3;
      return this.getRandomInt(range, strokeDasharray);
    }
    return number;
  }

  // below code used to export the chart as .png, .jpeg and .svg file formats
  exportChart(type: string, card: CardTitles = null) {
    if (card) {
      let filename = card.basicChart._header.replace(/[^a-zA-Z ]/g, '');
      card.basicChart.saveImageToFile(filename + '.' + type);
    } else if (this.mainGridFlexChart) {
      let filename = this.mainGridFlexChart._header.replace(/[^a-zA-Z ]/g, '');
      this.mainGridFlexChart.saveImageToFile(filename + '.' + type);
    }
  }

  chartRenderedCalled(lineChart: WjFlexChart, card: CardTitles = null) {
    if (card) {
      card.basicChart = lineChart;
    } else {
      this.mainGridFlexChart = lineChart;
    }

    lineChart.hostElement.addEventListener(
      'mousemove',
      (e) => {
        e.preventDefault();

        e.stopImmediatePropagation();
      },
      true
    );

    lineChart.hostElement.addEventListener(
      'mouseleave',
      (e) => {
        e.preventDefault();

        e.stopImmediatePropagation();
      },
      true
    );

    // Add accessibility features once the chart is rendered

    lineChart.rendered.addHandler((sender, e) => {
      // For FlexChart.
      const hostElement = sender.hostElement;
      hostElement.tabIndex = -1;
      // For focusing on header.

      let headerElement = hostElement.querySelector('.wj-header') as HTMLElement;

      if (headerElement && sender.header) {
        headerElement.tabIndex = 0;
        headerElement.addEventListener('focus', (e) => {
          let target = e.target as HTMLElement;
          target.classList.add('tab-focused-header');
        });

        // Remove the class when focus is removed from the header.
        headerElement.addEventListener('blur', (e) => {
          let target = e.target as HTMLElement;
          target.classList.remove('tab-focused-header');
        });
      }

      // Handle legends for accessibility

      let legends = hostElement.querySelectorAll('.wj-legend g text');
      legends.forEach((legend) => {
        const legendElement = legend as HTMLElement;
        legendElement.setAttribute('aria-label', legendElement.textContent);
        legendElement.tabIndex = 0;
        legendElement.addEventListener('focus', (e) => {
          let target = e.target as HTMLElement;
          target.classList.add('tab-focused-legend');
        });

        // Remove the class when focus is removed from the header.

        legendElement.addEventListener('blur', (e) => {
          let target = e.target as HTMLElement;
          target.classList.remove('tab-focused-legend');
        });
      });

      // Cast sender to FlexChart to access the series property

      const chart = sender as WjFlexChart;

      // Handle series elements for accessibility

      let xAxisLabels = hostElement.querySelectorAll('.wj-axis-x text');
      //extract symbol like $,%,£
      let yAxisLabels = hostElement.querySelectorAll('.wj-axis-y text');
      let symbolBefore = ``;
      let symbolAfter = ``;
      if (yAxisLabels.length > 0) {
        let labelText = yAxisLabels[0].textContent.trim();
        let match = labelText.match(/^([^\d.,]*)([\d.,]+)([^\d.,]*)$/);
        if (match) {
          symbolBefore = match[1]; // Special char before the number
          symbolAfter = match[3]; // Special char after the number
        }
      }

      for (let seriesIndex = 0; seriesIndex < chart.series.length; seriesIndex++) {
        //   console.log(`series index=`,seriesIndex);

        let currentSeries = chart.series[seriesIndex];
        let element = currentSeries.hostElement.querySelectorAll('rect');
        if (element.length <= 0) {
          element = currentSeries.hostElement.querySelectorAll('ellipse');
        }
        for (let index = 0; index < element.length; index++) {
          const ele = element[index];
          // functionalIndex is used to navigate through the axisLabels and series values.
          let functionalIndex = index;
          // if the value at index is undefined means the element is not present on the DOM, therefore functional index will be the next index for which value is defined.
          while (!currentSeries._values[index] && index < element.length) {
            index++;
            functionalIndex = index;
          }

          // If series is hidden, skip setting the attributes.
          if (chart.series[seriesIndex].visibility === SeriesVisibility.Hidden) {
            continue;
          }
          // Set the content for attributes.

          // let content = `<b>${currentSeries.name}</b><br/>${xAxisLabels[functionalIndex].textContent} $${currentSeries._values[functionalIndex]}`;
          let value = currentSeries._values[functionalIndex];
          let content = '';
          // Case 1: Symbol is after the number (like '%')
          if (symbolAfter) {
            let value = Math.round(currentSeries._values[functionalIndex] * 10000) / 100;
            content = `<b>${currentSeries.name}</b><br/>${xAxisLabels[functionalIndex].textContent} 
                          ${value}${symbolAfter}`;
            ele.ariaLabel = `${currentSeries.name}, ${xAxisLabels[functionalIndex].textContent}, ${value}${symbolAfter}`;

            // Case 2: Symbol is before the number (like '$' or '£')
          } else if (symbolBefore) {
            content = `<b>${currentSeries.name}</b><br/>${xAxisLabels[functionalIndex].textContent} 
                          ${symbolBefore}${value}`;
            ele.ariaLabel = `${currentSeries.name}, ${xAxisLabels[functionalIndex].textContent}, ${symbolBefore}${value}`;
          } else {
            // No special character, just show the value
            content = `<b>${currentSeries.name}</b><br/>${xAxisLabels[functionalIndex].textContent}
                          ${value}`;
            ele.ariaLabel = `${currentSeries.name}, ${xAxisLabels[functionalIndex].textContent}, ${value}`;
          }
          // Set the attribute.
          // adding atribute is causing image download to stop. needs fix//
          //ele.setAttribute('aria-label', content);
          // ele.ariaLabel = `${currentSeries.name}, ${xAxisLabels[functionalIndex].textContent}, ${symbolAfter || symbolBefore || ''}${value}`;

          // Make the element focusable with "Tab".
          ele.tabIndex = 0;

          // Add a class when the element is focused.
          ele.addEventListener('focus', (e) => {
            e.target.classList.add('tab-focused');
            lineChart.tooltip.show(e.target, content);
            ele.addEventListener('keydown', (e) => {
              if (e.key === 'Escape') {
                lineChart.tooltip.hide();
              }
            });
          });

          ele.addEventListener('mouseover', (e) => {
            lineChart.tooltip.show(e.target, content);
          });

          // Remove the class when the focus is removed from the element.

          ele.addEventListener('blur', (e) => {
            e.target.classList.remove('tab-focused');
          });
        }
      }
    });
  }

  updatingView(transposedGrid: WjTransposedGrid | wjGrid.FlexGrid) {
    if (transposedGrid.rowHeaders.columns.length > 1) transposedGrid.rowHeaders.columns[1].visible = false;

    transposedGrid.rowHeaders.columns[0].header =
      this.processedBaseTableData &&
      this.processedBaseTableData.processedColumn &&
      this.processedBaseTableData.processedColumn.length > 0 &&
      this.processedBaseTableData.processedColumn[0].header;

    for (let i = 0; i < transposedGrid.columnHeaders.columns.length; i++) {
      transposedGrid.columnHeaders.columns[i].header = transposedGrid.getCellData(0, i, false);
    }
  }
  transposedInitialize(transposedGrid, card: CardTitles, isManualCall: boolean = false, gridHeaderId: string = '') {
    transposedGrid.allowResizing = wjGrid.AllowResizing.BothAllCells;

    if (gridHeaderId && transposedGrid) transposedGrid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
    transposedGrid.addEventListener(transposedGrid.hostElement, 'click', (e) => {
      let ht = transposedGrid.hitTest(e);
      if (ht.row != -1 && ht.col != -1) {
        this.transposeGridClickFlag = true;
      }

      if (ht.row == 0) {
        for (let i = 0; i < transposedGrid.rows.length; i++) {
          transposedGrid.rows[i].isSelected = true;
        }
        transposedGrid.copyHeaders = wjGrid.HeadersVisibility.Row;
      } else {
        transposedGrid.copyHeaders = wjGrid.HeadersVisibility.All;
      }
      transposedGrid.hostElement.addEventListener('keydown', (e) => {
        this.lastPressedKey = e.code;
      });
    });

    transposedGrid.itemFormatter = function (panel, r, c, cell) {
      if (r == 0 && panel.rows && panel.rows[r]) {
        panel.rows[r].height = 40;
      }
      // adjusting width but flickring in UI
      // panel.columns[c].width = (c == 0 && panel.cellType != 1) ? 230 : 130;
      if (panel.cellType != 3) {
        if (
          panel.cellType == 1 &&
          r != 1 &&
          (panel.rows[r].binding == 'yyyymm' || panel.rows[r].binding == 'firmValue')
        ) {
          cell.style.background = '#f5f7ff';
          cell.style.color = '#404040';
          cell.style.fontWeight = '600';
          cell.style.paddingLeft = '1rem';
          cell.style.paddingRight = '1rem';
          cell.style.paddingTop = '0.75rem';
          cell.style.paddingBottom = '0.75rem';
          // cell.setAttribute('role', 'columnheader');
        }
        // else {
        // cell.style.height = '60px';
        // cell.style.padding = '10px';
        // }
        cell.style.textAlign = 'left';
      } else if (panel.cellType == 3) {
        if (c == 0 && panel.columns && panel.columns[c]) {
          cell.style.color = '#404040';
          cell.style.fontWeight = '600';
          // panel.columns[0].width = 200;
        }
        if (panel.rows[r].binding == 'yyyymm') {
          cell.style.color = '#404040';
          cell.style.background = '#f5f7ff';
          cell.style.paddingLeft = '1rem';
          cell.style.paddingRight = '1rem';
          cell.style.paddingTop = '0.75rem';
          cell.style.paddingBottom = '0.75rem';
          // cell.setAttribute('role', 'columnheader');
        } else {
          // cell.style.background = "white";
          // cell.style.height = '60px';
          //cell.style.padding = '10px';
        }
      }
      panel.columns[0].wordWrap = true;
      if (panel.cellType == 1) {
        let currentcol = panel.columns[c].visibleIndex + 1;
        cell.setAttribute('aria-colindextext', 'column ' + currentcol);
        let currentrow = panel.rows[r].visibleIndex + 1;
        cell.setAttribute('aria-rowindextext', 'row ' + currentrow);
      }
    };

    transposedGrid.refreshed.addHandler((s, e) => {
      let ch = s.hostElement.querySelectorAll('div [aria-hidden=true]');
      if (ch) {
        ch.forEach((e) => e.setAttribute('aria-hidden', false));
      }
    });

    if (!isManualCall) {
      if (!card) {
        this.mainGridBaseFlexGrid = transposedGrid;
      } else {
        card.basicFlexGrid = transposedGrid;
      }
    }
    transposedGrid.select(1, 0);
    this.transposedGrid = transposedGrid;
  }

  focusOnTransposeGrid(s, event) {
    if (s) {
      s.select(1, 0);
    }
  }
  updateSelection(requestOrigin: any = null, refreshFullGrid: boolean = false) {
    let requestbody: any = this.peercheckMessageHandlerService.createRequestBody(
      this.userSettingsModel,
      this.firmBasicData,
      'gas',
      false,
      null,
      null,
      this.shouldCheckMetricGroup
    );
    if (!requestbody && !this.firmBasicData && !this.pcViewCols) {
      return;
    }

    this.isDataLoading = true;
    this.updateDataLoadingState(this.isDataLoading);
    this.userSettingsModel.cardTitles = [];

    if (this.reorder || this.editReorderFlag) {
      const metrics = requestbody['metrics'];
      for (let i = 0; i < this.metricIdArr.length; i++) {
        if (metrics.indexOf(this.metricIdArr[i]) == -1) {
          this.metricIdArr.splice(i, 1);
          i--;
        }
      }

      for (let i = 0; i < metrics.length; i++) {
        if (this.metricIdArr.indexOf(metrics[i]) == -1) {
          this.metricIdArr.push(metrics[i]);
        }
      }

      requestbody['metrics'] = this.metricIdArr;
    }
    this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getDefaultOrSelectedFirmId, requestbody)
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
          this.showBasicTable = false;
          this.showDetailsTable = false;
          this.processedBaseTableData = new ProcessedBaseTableData();
          this.expandDataRaw = result;
          let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(
            requestbody.endPeriod,
            requestbody.startPeriod
          );
          this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
          // PROCESS API RESPONSE
          if (this.peercheckMessageHandlerService && result) {
            let response = Array.isArray(result) && result.length == 1 ? result[0] : result;
            this.processedMainGridData = this.peercheckMessageHandlerService.processDataForMainGrid(
              response,
              this.userSettingsModel,
              null,
              this.pcViewCols,
              this.firmBasicData,
              this.yoyHeader
            );
            if (
              this.processedMainGridData &&
              this.processedMainGridData.processedData &&
              this.processedMainGridData.processedData.length > 0 &&
              this.metricItemsStream &&
              this.metricItemsStream._value &&
              this.metricItemsStream._value.length > 0
            ) {
              const mainGridMetricId = this.processedMainGridData.processedData.map((data) => {
                return data.metricId;
              });
              this.metricIdArr = mainGridMetricId;
              this.dragDone.emit({dragIndex: null, dropIndex: null, metricIdArr: this.metricIdArr});
              let metricId = this.metricItemsStream._value.map((data) => {
                return data.id;
              });

              metricId = metricId.filter((id) => {
                return mainGridMetricId.indexOf(id) != -1;
              });

              for (let i = 0; i < metricId.length; i++) {
                if (metricId[i] != mainGridMetricId[i]) {
                  this.editReorderFlag = true;

                  break;
                }
              }
            }
          }
          if (this.processedMainGridData.hasEmptyResult) {
            this.alerts.push({
              type: 'warning',
              msg: 'No data available to display.',
              closeable: true,
            });
          }
          this.handleRequestOriginChanges(requestOrigin);
          this.onInitialized(this.flexGrid, null, true);
          this.setCardTitle();
          eventDispatcher.next({type: ActionTypes.SetProcessedData, payload: this.processedMainGridData});
          this.globalservice.skipFocusTwiceCombobox();
        },
        (error) => {
          this.handleRequestOriginChanges(requestOrigin, true);
          this.errorMessage = error.error;

          if (requestOrigin.requestFrom && requestOrigin.requestFrom != 'openViewSettings') {
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
        }
      );

    if (refreshFullGrid) {
      this.onInitialized(this.flexGrid, null, true);
    }
  }
  handleSelectionChangedTG(sender: WjTransposedGrid, args: wjGrid.CellRangeEventArgs) {
    let selectedCell = args.panel.getCellElement(args.row, args.col);
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp' || this.lastPressedKey == 'Tab') {
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  getMetricGroup(metrics: any[]) {
    let metricGroups = metrics
      .map(({groupId, groupName}) => ({groupId, groupName}))
      .filter((item, i, arr) => arr.findIndex((t) => t.groupId === item.groupId) === i);
    let finalMetric = [];
    metricGroups.forEach((mg, index) => {
      finalMetric.push({
        id: mg.groupId,
        groupId: null,
        groupName: null,
        name: mg.groupName,
        isIndeterminate: false,
      });
      let result = metrics.filter((item) => item.groupName === mg.groupName);
      // result.forEach(r => {
      //   r.isIndeterminate= false;
      //   finalMetric.push(r);
      // }
      // )
      finalMetric = finalMetric.concat(result);
    });
    return finalMetric;
  }

  formatItem(s: any, e: any, card: CardTitles = null) {
    if (e.panel == s.cells) {
      let binding = s.rows[e.row].binding || s.columns[e.col].binding;

      if (this.peercheckMessageHandlerService && binding != 'yyyymm') {
        let dtType = null;
        let dtCurr = null;

        if (card) {
          dtType = card.processedBaseTable.prossedDataType;
          dtCurr = card.processedBaseTable.prossedDataCurrency;
        } else {
          dtType = this.processedBaseTableData.prossedDataType;
          dtCurr = this.processedBaseTableData.prossedDataCurrency;
        }

        if (!dtType) return;

        if (dtType.startsWith('percent') && e.cell.textContent && e.cell.textContent.trim() == '0.0%') {
          // e.cell.innerHTML = parseFloat(e.cell.textContent).toFixed(1) + '%';
          e.cell.innerHTML = '--';
        }

        // eslint-disable-next-line max-len
        if (dtType.startsWith('currency') && e.cell.textContent) {
          if (e.cell.textContent.includes('NZ$0') && e.cell.textContent.length == 4) e.cell.innerHTML = '--';
          else if (e.cell.textContent.includes('A$0') && e.cell.textContent.length == 3) e.cell.innerHTML = '--';
          else if (
            (e.cell.textContent.includes('£0') || e.cell.textContent.includes('$0')) &&
            e.cell.textContent.length == 2
          )
            e.cell.innerHTML = '--';
          // else if (e.cell.textContent.includes('£0') && e.cell.textContent.length == 2)
          //   e.cell.innerHTML = '--';
        }
      }

      if (!e.cell.textContent || e.cell.textContent == '0') {
        e.cell.innerHTML = '--';
      }
    }
  }

  handleRequestOriginChanges(requestOrigin: any, isFailure: boolean = false) {
    if (!requestOrigin) return;

    if (requestOrigin.requestFrom && requestOrigin.requestFrom == 'openViewSettings') {
      const previousSettings = requestOrigin.previousSettings ? requestOrigin.previousSettings : null;
      const currentSettings = requestOrigin.currentSettings ? requestOrigin.currentSettings : null;
      if (isFailure) {
        this.userSettingsModel.viewsSettings = previousSettings || new ViewsSettings();
        this.alerts.push({
          type: 'warning',
          msg: 'Unable to apply settings.',
          closeable: true,
        });
      } else {
        this.userSettingsModel.viewsSettings = currentSettings || new ViewsSettings();
        this.alerts.push({
          type: 'success',
          msg: 'Settings applied successfully.',
          timeout: 2500,
          closeable: true,
        });
      }
    } else if (requestOrigin.requestFrom && requestOrigin.requestFrom == 'metricOnItemsSelected') {
      // Perform Action for metricOnItemsSelected()
    } else if (requestOrigin.requestFrom && requestOrigin.requestFrom == 'peerGroupOnItemsSelected') {
      // Perform Action for peerGroupOnItemsSelected()
    } else if (requestOrigin.requestFrom && requestOrigin.requestFrom == 'timeRangeOnItemsSelected') {
      // Perform Action for timeRangeOnItemsSelected()
    } else if (requestOrigin.requestFrom && requestOrigin.requestFrom == 'populateInputValuesForCustomize') {
      // Perform Action for populateInputValuesForCustomize()
    }
  }

  handleFixedDateRangeSelection() {
    if (
      !(
        this.userSettingsModel &&
        this.userSettingsModel.fixedTimeRange &&
        this.userSettingsModel.fixedTimeRange.startDateText &&
        this.userSettingsModel.fixedTimeRange.endDateText
      )
    ) {
      return;
    }

    let startDateText = this.userSettingsModel.fixedTimeRange.startDateText;
    let endDateText = this.userSettingsModel.fixedTimeRange.endDateText;

    if (
      this.createViewForm &&
      this.createViewForm.form &&
      this.createViewForm.form.controls.startDate.valid &&
      this.createViewForm.form.controls.endDate.valid
    ) {
      let startDateMatch = startDateText.match(this.validatePattern);
      let endDateMatch = endDateText.match(this.validatePattern);

      let period = this.peercheckMessageHandlerService.getPeriod(
        this.userSettingsModel,
        null,
        null,
        false,
        startDateMatch,
        endDateMatch
      );
      if (
        Number(period[0]) < Number(period[1]) &&
        this.userSettingsModel.metricSelectedItems &&
        this.userSettingsModel.peerGroupSelectedItems &&
        this.userSettingsModel.metricSelectedItems.length &&
        this.userSettingsModel.peerGroupSelectedItems.length
      ) {
        this.userSettingsModel.fixedTimeRange.startPeriod1 = period[0];
        this.userSettingsModel.fixedTimeRange.endPeriod1 = period[1];
        this.updateSelection();
      }
    }
  }

  tooltipContent(hti) {
    let item = hti.item;

    let currencySym = '&#x24;';
    if (item.currency == 'GBP') {
      currencySym = '&#xA3;';
    } else if (item.currency == 'USD' || item.currency == 'AUD' || item.currency == 'CAD') {
      currencySym = '&#x24;';
    } else if (item.currency == 'EUR') {
      currencySym = '&#x20AC;';
    }

    let tooltipContent = null;
    if (item.dtTyp.startsWith('percent')) {
      tooltipContent = `<div class="font-weight-bold">${hti.name}</div> ${item.yyyymm} ${(hti.value * 100).toFixed(
        1
      )}%`;
    } else if (item.dtTyp.startsWith('currency') || item.dtTyp.startsWith('dollar')) {
      tooltipContent = `<div class="font-weight-bold">${hti.name}</div> ${item.yyyymm} ${currencySym}${hti.value}`;
    } else {
      tooltipContent = `<div class="font-weight-bold">${hti.name}</div> ${item.yyyymm} ${hti.value}`;
    }

    return tooltipContent;
  }

  loadedRows(s: WjTransposedGrid) {
    s.columns.defaultSize = 150;
    s.autoSizeRows();
    if (s.columns.length > 1) {
      s.autoSizeColumns();
    }
    s.select(1, 0);
  }

  getViewDetails(viewId: string) {
    this.isDataLoading = true;
    this.updateDataLoadingState(this.isDataLoading);
    this.service
      .get(
        environment.FIAdminBaseEndpoint + 'v1/view/' + viewId + '/firm/' + this.getDefaultOrSelectedFirmId + '/details',
        ''
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
          this.viewDetails = result;
          this.peercheckMessageHandlerService.setViewDetails = this.viewDetails;
          this.populateInputValues();
        },
        (error) => {
          // this.isDataLoading = false;
          this.updateDataLoadingState(this.isDataLoading);
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  onClickPPG() {
    this.openPPG = true;
  }

  retrieveCreateViewSelection(isPPGOpen) {
    this.openPPG = false;
  }

  async updateGrid(
    processedMainGridData: ProcessedMainData,
    processedBaseTableData: ProcessedBaseTableData = null,
    skipEmptyError: boolean = false
  ) {
    this.updateDataLoadingState(true);
    this.processedMainGridData = processedMainGridData;
    if (this.processedMainGridData.hasEmptyResult && !skipEmptyError) {
      this.alerts.push({
        type: 'warning',
        msg: 'No data available to display.',
        closeable: true,
      });
    }
    this.processedBaseTableData = processedBaseTableData;
    this.selectedMetric = null;
    this.onInitialized(this.flexGrid, null, true);
    if (this.shouldCardDisplay) {
      await this.setInputValues();
      this.setCardTitle();
    }
    this.updateSummary();
    this.updateDataLoadingState(false);
  }

  updateDataLoadingState(state: boolean) {
    this.dataLoadingStateChange.emit(state);
  }

  updateSummary() {
    this.summary = new DescriptionSummary();
    if (this.userSettingsModel.timeRangeSelected == 'Rolling' && this.userSettingsModel.rollingTimeRangeSelectedItems)
      this.summary.timeRange = this.userSettingsModel.rollingTimeRangeSelectedItems.name;
    else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      const date = new Date();
      date.setDate(1);
      date.setFullYear(parseInt(year));
      date.setMonth(parseInt(month) - 1);
      const endDateText = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
      this.summary.timeRange = 'Year to date (01/' + date.getFullYear() + ' through ' + endDateText + ')';
      //this._service.setDateSelectedSubject(this.summary.timeRange);
    } else {
      this.summary.timeRange =
        this.userSettingsModel.fixedTimeRange.startDateText +
        ' through ' +
        this.userSettingsModel.fixedTimeRange.endDateText;
      //this._service.setDateSelectedSubject(this.summary.timeRange);
    }
    this.summary.metrics = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.metricSelectedItems,
      true
    );
    this.summary.peerGroups = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.peerGroupSelectedItems,
      true
    );
    this.summary.practices = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.practiceGroupSelectedItems,
      true
    );
    this.summary.offices = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.officeSelectedItems,
      true
    );
    this.summary.titles = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.titleSelectedItems,
      true
    );
    this.summary.associateExp = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.associateYearSelectedItems,
      true
    );
    this.summary.srAssociateExp = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.srAssociateYearSelectedItems,
      true
    );
    this.summary.partnerYearsExp = this.peercheckMessageHandlerService.getCommaSeparatedString(
      this.userSettingsModel.partnerYearSelectedItems,
      true
    );
  }

  closeBasicOrDetailsTable(card: CardTitles = null) {
    if (card) {
      card.shouldShowBasicTable = false;
      card.showDetailsTable = false;
    } else {
      this.showBasicTable = false;
      this.showDetailsTable = false;
    }
  }

  resetToDefault(processedMainGridData) {
    this.processedMainGridData = processedMainGridData;
    this.onInitialized(this.flexGrid, null, true);
    this.closeBasicOrDetailsTable();
  }

  getEmptyGridData() {
    return this.peercheckMessageHandlerService.processDataForMainGrid(
      null,
      this.userSettingsModel,
      null,
      this.pcViewCols,
      this.firmBasicData
    );
  }
  freezeColumnToggle() {
    if (this.isColumnFreezed) this.frozenColumnCount = 0;
    else this.frozenColumnCount = 3;

    this.isColumnFreezed = !this.isColumnFreezed;
  }
  handleSelectionChanged(s: wjGrid.FlexGrid, isCard, args: wjGrid.CellRangeEventArgs) {
    if (args.row != -1 && s.activeCell) {
      if (!isCard) {
        this.gridsSelectedCol = args.col;
        this.gridsSelectedRow = args.row;
        if (s.columnHeaders.activeCell) this.gridsSelectedColHeader = true;
        else this.gridsSelectedColHeader = false;
      } else {
        this.cardGridsSelectedCol = args.col;
        this.cardGridsSelectedRow = args.row;
      }
    }
    if (
      args.panel &&
      s.activePanel == s.cells &&
      s.activePanel != s.columnHeaders &&
      args.row != -1 &&
      !Object.is(args.row, -0) &&
      !Object.is(args.col, -0)
    ) {
      setTimeout(() => {
        let column = args.getColumn(); // gets the column from grid that has selection
        let cell = args.panel.getCellElement(args.row, args.col);
        if (cell) {
          // gets the hyperlink element present in the currently selected cell
          let link = cell.querySelector('a');
          // show tooltip/popup if necessary
          if (args.row != -1 && column.binding == 'metricName' && link) {
            let tt = args.getRow().dataItem['_tt'];
            if (tt) {
              tt.show();
            }
          } else if (this.activePopup) {
            this.activePopup.hide();
            this.activePopup = null;
          }
        }
        if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
          let selectedCell = args.panel.getCellElement(args.row, args.col);
          if (selectedCell) {
            selectedCell.scrollIntoView({
              block: 'nearest',
              behavior: 'smooth',
              inline: 'nearest',
            });
          }
        }
      }, 50);
    } else if (this.activePopup) {
      // hide the active tooltip, if any, when focus moves to header cells
      this.activePopup.hide();
      this.activePopup = null;
    }
  }

  handleKeyDown(flexGrid, isCard, event) {
    let row = 0,
      col = 0;
    if (isCard) {
      col = this.cardGridsSelectedCol;
      row = this.cardGridsSelectedRow;
    } else {
      col = this.gridsSelectedCol;
      row = this.gridsSelectedRow;
    }

    if (!isCard && event.ctrlKey && (event.code === 'ArrowUp' || event.code === 'ArrowDown')) {
      this.isMainGridKeyDownCalled++;
      if (this.isMainGridKeyDownCalled == 1) {
        const isCell = wjcCore.hasClass(event.target as Element, 'wj-cell');
        const isRowHeader = wjcCore.closestClass(event.target as Element, 'wj-rowheaders');
        if (isCell && isRowHeader) {
          let ht = flexGrid.hitTest(event.target);
          let dest = event.code == 'ArrowUp' ? (ht.row == 0 ? 0 : ht.row - 1) : ht.row + 1;
          flexGrid.rows.moveElement(ht.row, dest); // move/drag rows
          flexGrid.select(dest, 0, flexGrid._gpRHdr); // move selection to destination header cell

          // Update the live region for screen readers
          if (ht.row != dest && dest < flexGrid.rows.length) {
            // let srcItem = flexGrid.rows[ht.row].dataItem;
            // let trgtItem = flexGrid.rows[dest].dataItem; // dataItem reference if needed
            this.metricIdArr = flexGrid.rows.map((obj) => {
              return obj.dataItem.metricId;
            });
            if (this.callerComponent == 'create-view')
              this.dragDone.emit({dragIndex: ht.row, dest, metricIdArr: this.metricIdArr});
            const liveRegion = document.getElementById('liveRegion');
            liveRegion.textContent = `Row  ${ht.row + 3} and Row ${dest + 3} have been swapped.`;
          }
        }
      } else {
        this.isMainGridKeyDownCalled = 0;
      }
    } else if (wjcCore.closestClass(event.target, 'wj-colheaders') && event.code == 'Space') {
      this._lastFocusCell = this.flexGrid.hitTest(event.target);
      let el = this._lastFocusCell.querySelector('a');
      el.click();
      event.preventDefault();
    } else if (event.code === 'Space') {
      if (col == 0) {
        let cell = flexGrid.cells.getCellElement(row, col);
        if (cell) {
          flexGrid.select(row, col);
          let input = cell.querySelector('a');
          if (input) input.click();
          event.preventDefault();
        }
      }
    } else if (event.keyCode === wjcCore.Key.Tab) {
      this.isMainGridKeyDownCalled++;
      if (this.isMainGridKeyDownCalled == 1) {
        this.axeToolService.enableFocusFromCloumnAndRowHeaders(
          event,
          'button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
        );
      } else {
        this.isMainGridKeyDownCalled = 0;
      }
    }
  }

  focusOnGrid(s, isCard, args) {
    if (this._lastFocusCell) s.select(new wjGrid.CellRange(-0, this._lastFocusCell.col), true, s.columnHeaders);
    else s.select(new wjGrid.CellRange(-0, 1), true, s.columnHeaders);
    this._lastFocusCell = null;
  }
  attachPopover(element: HTMLElement, item: any, flexGrid) {
    let content = `<div style="width: 300px;padding:10px">
    <h3 class="popover-header">${item.metricName}</h3>
    <div class="popover-body">
      ${item.metricDefinition}
    </div>
      </div>`;

    let host = document.createElement('div');
    host.classList.add('wj-note');
    host.innerHTML = content;

    // creating popup for note
    let popup = new wjInput.Popup(host, {
      owner: element,
      position: 'Right',
      fadeIn: false,
      fadeOut: false,
      removeOnHide: true,
      hideTrigger: 'LeaveOwner',
      showTrigger: 'EnterOwner',
      showing: (s, e) => {
        if (this.activePopup) {
          // hide previous open popup, if any
          this.activePopup.hide();
          this.activePopup = null;
        }
      },
      shown: (s, e) => {
        this.activePopup = s;
      },
    });

    popup.hostElement.addEventListener('keydown', (event) => {
      if (this.activePopup != null) this.activePopup.hide();
      // Create and dispatch the same keydown event on the grid
      const newEvent = new KeyboardEvent('keydown', {
        key: event.key,
        code: event.code,
        keyCode: event.keyCode,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
        charCode: event.charCode,
        bubbles: true,
        cancelable: true,
      });

      flexGrid.hostElement.dispatchEvent(newEvent);
    });

    item['_tt'] = popup;
  }
  closePGPopup() {
    this.modalService.dismissAll();
    this.flexGrid.select(new wjGrid.CellRange(-0, this._lastFocusCell.col), true, this.flexGrid.columnHeaders);
  }
}
